import React from "react";
import NavHelper from "../../helper/NavHelper";
import { Link } from "react-router-dom";
import Navmenu from "./Navmenu";
import SuportBtn from "./SuportBtn";
import ScrollButton from "../ScrollButton";
// import Offcanvas from "./Offcanvas";

class Header extends NavHelper {
  render() {
    const mobileactive = this.state.matches ? "breakpoint-on" : "";
    // smallmobileactive = this.state.matches ? "d-none" : "";
    const stickyheader = this.state.stickyheader ? "sticky-on" : "";
    return (
      <>
        <header className={`sticky-header ${stickyheader}`}>
          {/* Header Topbar */}
          {/* <div className="header-topbar">
            <div className="container-fluid container-1600">
              <div className="header-topbar-inner d-md-flex align-items-center justify-content-between">
               
                <ul className="contact-info headerAddres">
                  <li>
                    <Link to="#">
                      <i className="far fa-phone" />
                      +91 78670 16410
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="far fa-envelope-open" />
                      indcosys@gmail.com
                    </Link>
                  </li>
                  <li>
                    <i className="far fa-map-marker-alt" />
                    Coimbatore, INDIA
                  </li>
                </ul>
              
                <ul className="social-links headerScl">
                  <li>
                    <Link to="#">
                      <i className="fab fa-youtube" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-instagram" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          {/* Header Menu  */}
          <div className="header-nav">
            <div className="container-fluid container-1600">
              <div className={`nav-container ${mobileactive} navHg`}>
                {/* Site Logo */}
                <div className="site-logo">
                  <Link to="/">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
                      alt="logo"
                      className="HeadLogo"
                    />
                  </Link>
                </div>
                {/* Main Menu */}
                <div
                  className={
                    this.state.togglemethod === true ? "mainDiVmenu" : ""
                  }
                >
                  <div
                    className={
                      this.state.togglemethod === true
                        ? "nav-menu d-lg-flex align-items-center menu-on"
                        : "nav-menu d-lg-flex align-items-center"
                    }
                  >
                    {/* Navbar Close Icon */}
                    <div className="navbar-close" onClick={this.toggleClass}>
                      <div className="cross-wrap thiru">
                        <span />
                        <span />
                      </div>
                    </div>
                    {/* Mneu Items */}
                    <div className="menu-items">
                      <Navmenu />
                    </div>
                    {/* Pushed Item */}
                    <div className="nav-pushed-item" />
                  </div>
                </div>
                {/* Navbar Extra  */}
                <div className="navbar-extra d-lg-block d-flex align-items-center">
                  {/* Navbtn */}
                  <div className={`navbar-btn nav-push-item `}>
                    <SuportBtn />
                  </div>
                  {/* Navbar Toggler */}

                  <div
                    className={
                      this.togglemethod === true
                        ? "navbar-toggler active"
                        : "navbar-toggler"
                    }
                    onClick={this.toggleClass}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <ScrollButton />
      </>
    );
  }
}

export default Header;
