import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
// import Client from "../layouts/Client";
// import { Tab, Nav } from "react-bootstrap";

import line1 from "../../assets/img/lines/11.png";
import line2 from "../../assets/img/lines/12.png";

// import img from "../../assets/img/tab-block.jpg";
import solution1 from "../../assets/img/solution/solu4.jpg";
import solution2 from "../../assets/img/solution/solu3.jpg";
import solution3 from "../../assets/img/solution/solu7.jpg";
import solution4 from "../../assets/img/solution/solu8.jpg";

const AssemblylineAutomation = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>MELZ Indcosys | Assembly line Automation</title>
        <meta name="description" content="Industrial Automation" />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "", bgimg: "ala" }} />
      <section className="service-section grey-bg service-line-shape section-gap">
        <div className="container">
          {/* Section Title */}
          <div className="section-title text-center both-border mb-50 mobile-mb">
            <span className="title-tag">Solutions </span>
            <h2 className="title">Assembly line Automation</h2>
          </div>

          <div className="tab-text-block left-image with-left-circle">
            <span></span>
            <div
              id="ILTS"
              className="row align-items-center justify-content-center mb-50"
            >
              <div className="col-lg-5 col-md-10 mobile-hd">
                <div className="block-image">
                  <img src={solution1} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">In-line Test Systems </h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      MELZ offer automated and semi-automated in-line test
                      systems with high-precision inspection to identify and
                      eliminate flawed products
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      ILTS are custom designed to accommodate high-volume and/or
                      high-complexity
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      PC or PLC based system with Touch-screen monitors and
                      alerts for operators
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Integrates seamlessly into production environments without
                      slowing down operations
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Synchronized Analog & Digital subsystems
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Powerful Debug and Production user interfaces
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Smaller foot print to save production space and enhance
                      better EOL testing
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 desktop-hd mobile-mt">
                <div className="block-image">
                  <img src={solution1} alt="" />
                </div>
              </div>
            </div>

            <span id="process_validation_machines"></span>
            <div
              id="EOL-TS"
              className="row align-items-center justify-content-center mb-50"
            >
              <div className="col-lg-12 col-md-12">
                <div className="block-text">
                  <h2 className="title">End of Line Test Systems </h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      Application specific assembly stations with integrated
                      EOL-TS are effectively designed to meet the requirement of
                      semi or fully automated line, lean manufacturing,
                      continuous motion within high-throughput manufacturing
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Assembly Automation including Special presses, leak check,
                      Poke Yoke checking, Dosing & Dispensing, Riveting &
                      Screwing, Sealing, Pick & Place, Stacking & Packaging
                    </li>
                  </ul>
                </div>
                <div className="block-image">
                  <img className="soluCenter" src={solution2} alt="" />
                </div>
                <div className="block-text">
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      Test systems can feature simple pass/fail indication, test
                      report printout, data acquisition, data logging and
                      product marking with seamless integration & best support
                      availability
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      The EOL-TS may be delivered as stand-alone system,
                      incorporating features such as Human-Machine Interface
                      (HMIs), finger swipes, Barcode readers, and label
                      machines, or integrated as part of a broader system
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-5 col-md-10 mobile-mt">
                <div className="block-image">
                  <img src={solution2} alt="" />
                </div>
              </div> */}
            </div>

            <span id="proto_type_testing"></span>
            <div
              id="ARS"
              className="row align-items-center justify-content-center mb-50"
            >
              <div className="col-lg-5 col-md-10 mobile-hd">
                <div className="block-image">
                  <img src={solution3} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">AUTOMATED ROBOTICS SYSTEMS </h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      Automated Robotic Systems are designed, integrated and
                      deployed using wide range of Robot manufacturer varying
                      from 3-axis robots (top entry robots), 6-axis robots
                      (articulated robots), collaborative robots (cobots), SCARA
                      robots, side entry robots
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      MELZ can involve in early project design and propose ARS
                      for implementing ROI by higher machine utilization, less
                      idle time for operators in automated soldering, welding,
                      pick n place, Material handling – Auto Loading /
                      unloading, Dispensing, Palletizing, Part Transfer, Machine
                      Tending and Testing.
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Solutions embedded with data logging, report generation
                      and complete data acquisition (wired and wireless)
                      creating a permanent record confirming built specification
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      We offer fully-supported robotic solutions that can
                      greatly simplify robot deployment including end clamping,
                      quick tool change & custom tooling to reduce costs and
                      offer collaborative options for machine operation.
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      ARS will be of Intuitive, intelligent Human Machine
                      Interface (HMI) and of great choice for standalone, lean,
                      inline transfer assembly lines to implement digital
                      factory and Industry 4.0
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 desktop-hd mobile-mt">
                <div className="block-image">
                  <img src={solution3} alt="" />
                </div>
              </div>
            </div>

            <span id="portable_test_rigs"></span>
            <div
              id="ATS"
              className="row align-items-center justify-content-center mb-50"
            >
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">ASSEMBLY TRACEABILITY SYSTEM</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      MELZ – ATS is a strong hold of its Industrial Assembly
                      Automation business which meets the client requirement of
                      Traceability from Inception to Completion
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Track and trace of critical components within the
                      manufacturing process embedded into process and
                      machineries technological changes.
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      ATS is designed to be well interconnected with field
                      hardware like 1D and 2D standardized barcode tags, QR
                      code, Radio frequency identification (RFID) readers, laser
                      scanner, Continuous Inkjet, high speed vision system and
                      laser marking
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Being Automation solution provider and system integrator
                      MELZ has rich experience in interfacing with various plant
                      / machine level control system environments such as
                      Siemens, Allen Bradley, Festo, Horner, Mitsubishi, Omron,
                      Etc both in-terms of hardware and software
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      ATS works in hand with Sensing, Control, Safety, Vision,
                      Motion and Robotics to provide extremely fast real time
                      responses making Traceability a valid
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Customer can get the benefit of seamless Data logging,
                      Report generation, Regulatory Compliance, Remote / Central
                      Monitoring & Diagnostics, Quality Control with Real time
                      transparency and direct implement of Industry 4.0
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 mobile-mt">
                <div className="block-image">
                  <img src={solution4} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="line-one">
            <img src={line2} alt="line-shape" />
          </div>
          <div className="line-two">
            <img src={line1} alt="line-shape" />
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
};

export default AssemblylineAutomation;
