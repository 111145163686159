import React from "react";
import { Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import training from "../../../assets/img/pdf/training.pdf";

//import img from "../../../assets/img/tab-block.jpg";

function Advancedtab(props) {
  return (
    <section className="advanced-tab section-gap p-t-20 p-b-20">
      <div className="container">
        {/* Tabs Buttons */}
        <div className="tab-buttons">
          <Tab.Container defaultActiveKey="ser2">
            <div className="row">
              <div className="col-md-5">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="ser2">
                      Installation testing & Commissioning
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ser1">
                      Proposal & Project Engineering
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="ser3">
                      Proto type Engineering & Estimation
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ser4">
                      Project Planning & Cost budgeting
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="ser5">
                      Approvals & Qualification Centre
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="ser6">
                      Annual Maintenance Contract - AMC
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ser7">
                      Training & Certification Courses
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-7">
                <Tab.Content>
                  <Tab.Pane eventKey="ser1">
                    <div className="tab-text-block  ">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-lg-12 col-md-12">
                          <div className="block-text">
                            <h2 className="title">
                              Proposal & Project Engineering
                            </h2>
                            <p>
                              MELZ offer products & services which are
                              professional, innovative and crafted to suit the
                              project requirement. With rich domain knowledge
                              and field expertise MELZ can offer solutions for
                              projects by doing detailed proposal including
                              Project specification requirement preparation,
                              selection of hardware, derivation of BoQ, IO
                              Summary, specification compliance report,
                              Qualification test and Approvals.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="ser2">
                    <div className="tab-text-block right-image ">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-lg-12 col-md-12 order-2 order-lg-1">
                          <div className="block-text">
                            <h2 className="title">
                              Installation testing & Commissioning
                            </h2>
                            <p>
                              MELZ with its well trained and equipped technical
                              resources can execute all types of activities
                              involved for installation & erection, field
                              studies and trials, pre and post commissioning
                              activities, site support during hand over phase
                              and process start-ups.
                            </p>

                            <p>
                              We can support in conducting Acceptance Test as
                              per the Project / Client / Consultation /
                              Contractor specifications including FAT - Factory
                              Acceptance Test & SAT - Site Acceptance Test.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="ser3">
                    <div className="tab-text-block  ">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-lg-12 col-md-12">
                          <div className="block-text">
                            <h2 className="title">
                              Proto type Engineering & Estimation
                            </h2>
                            <p>
                              It is true that any new product launches or mass
                              volume requires proto type engineering. MELZ with
                              dedicated mechanical design engineers and
                              prototyping experts can develop design iterations
                              and high-precision models within flexible
                              timelines. We work on building prototypes such as
                              3D printing (FDM), Rapid prototyping, Silicon
                              mould components, Fixture Designing, CNC machined
                              components in Aluminium/plastic, Fabrication, etc.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="ser4">
                    <div className="tab-text-block right-image ">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-lg-12 col-md-12 order-2 order-lg-1">
                          <div className="block-text">
                            <h2 className="title">
                              Project Planning & Cost budgeting
                            </h2>
                            <p>
                              As a universal truth planning is directly related
                              to any Projects irrespective of industries,
                              machineries and process. With our past experience
                              of successfully completing much simpler projects
                              to totally complex and critical projects for
                              Automotive, Aviation, Power Plants, Refineries,
                              Defence. All phases of the project can be divided,
                              developed, documented and deployed. We can
                              estimate and submit cost budgeting for new as well
                              as renovation projects.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="ser6">
                    <div className="tab-text-block right-image ">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-lg-12 col-md-12 order-2 order-lg-1">
                          <div className="block-text">
                            <h2 className="title">
                              Annual Maintenance Contract - AMC
                            </h2>
                            <p>
                              We have exclusive service and support engineers
                              who are capable of providing a comprehensive,
                              non-comprehensive and on call basis service
                              support for addressing the solutions implemented
                              by MELZ and any other third party supplied systems
                              and packages. Our Maintenance service is focused
                              to nullify the downtime of the equipment and
                              prevent unanticipated major breakdowns, conducts
                              periodic audits to measure required health
                              parameters including critical parameters of the
                              machineries and submit detailed summary and
                              suggestions on preventive maintenance actions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="ser7">
                    <div className="tab-text-block right-image ">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-lg-12 col-md-12 order-2 order-lg-1">
                          <div className="block-text">
                            <h2 className="title">
                              Training & Certification Courses
                            </h2>
                            <p>
                              We strongly believe that training enhances the
                              productivity of the man power resources and
                              directly assists in meeting the overall mission
                              and vision of the Organization. We offer various
                              training programs for the emerging Engineering
                              students and professionals, End users, Contractors
                              with respect to class room training, onsite
                              trainings, On the Job Trainings(OJT).
                            </p>
                            <Link
                              className="main-btn main-btn-3 mt-10-m"
                              target="_blank"
                              style={{ float: "right" }}
                              to={training}
                            >
                              Training Brochure
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </section>
  );
}

export default Advancedtab;
