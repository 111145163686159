import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
// import Client from "../layouts/Client";
// import { Tab, Nav } from "react-bootstrap";

import line1 from "../../assets/img/lines/11.png";
import line2 from "../../assets/img/lines/12.png";

// import img from "../../assets/img/tab-block.jpg";
import solution1 from "../../assets/img/solution/solu1.jpg";
import solution2 from "../../assets/img/solution/solu2.jpg";
import solution3 from "../../assets/img/solution/solu13.jpg";
import solution4 from "../../assets/img/solution/solu5.jpg";

const AutomatedTestingEquipments = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>MELZ Indcosys | Automated Test Machines</title>
        <meta name="description" content="Industrial Automation" />
      </MetaTags>
      <Header />
      <Breadcrumbs
        breadcrumb={{
          pagename: "",
          bgimg: "ate",
        }}
      />
      <section className="service-section grey-bg service-line-shape section-gap">
        <div className="container">
          {/* Section Title */}
          <div className="section-title text-center both-border mb-50 mobile-mb">
            <span className="title-tag">Solutions </span>
            <h2 className="title">Automated Test Machines</h2>
          </div>

          <div className="tab-text-block left-image with-left-circle">
            <span></span>
            <div
              id="product_test_benches"
              className="row align-items-center justify-content-center mb-50"
            >
              <div className="col-lg-5 col-md-10 mobile-hd">
                <div className="block-image">
                  <img src={solution1} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">PRODUCT TEST BENCHES & RIGS</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      Efficient & Ergonomic Design solutions for simpler to
                      complex Test Rigs & Benches
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Built on PC / PLC / PAC based platforms with interface
                      software to provide reduced test time
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      High-precision stand alone and modular type construction
                      in Hydraulic, Hydrostatic and Pneumatic test equipment
                      (Pressure, Flow, Level, Leak, Temperature, Torque)
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Ease to upgrade by interlinking the controls of
                      Electrical, Electronics, Instrumentation and Mechanical
                      Engineering and direct IIOT gateway to Industry 4.0
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      High-speed data acquisition (Force vs Displacement, Time
                      to function, Speed vs Torque, Flow vs Pressure)
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Designed to test & validate BS VI, Hybrid and EV compliant
                      vehicle sensors/modules/products
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Meet the stringent Test Compliance of Indian &
                      International Standards with Built-in calibration standard
                      module and self-test circuits (Built-in Test - BIT)
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Designed to perform 24x7 hrs of operation through-out the
                      year in ensuring product Reliability, Durability &
                      Performance
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Special Test rigs with integrated Environmental Chambers
                      to meet Environmental Stress Screening (ESS) compliance
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Complete turnkey involving detail study, design, software,
                      build, debug, on-site set-up and hands-on training.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 desktop-hd mobile-mt">
                <div className="block-image">
                  <img src={solution1} alt="" />
                </div>
              </div>
            </div>

            <span id="process_validation_machines"></span>
            <div className="row align-items-center justify-content-center mb-50">
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">
                    PROCESS VALIDATION MACHINES & SPECIAL PURPOSE MACHINES
                  </h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      MELZ is involved in providing process validation solutions
                      to confirm a process result with objective result
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Repeatability test check with defined tolerance
                      specification
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Built in feature for Remote Diagnosis and communicable
                      with Plant and Facility Management Gateway
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Executes process validation in terms of Installation
                      qualification (IQ), Operation Qualification (OQ),
                      Performance Qualification (PQ)
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Process Automation including Special presses, leak check,
                      Poke Yoke checking, Dosing & Dispensing, Riveting &
                      Screwing, Sealing, Pick & Place, Stacking & Packaging
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      High precision analog and digital instrumentation with DAQ
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Option for Barcode Printer & Scanner interface for
                      authentication and process documentation
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Communicating to automotive bus diagnostics (CAN, J1850 /
                      LIN)
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 mobile-mt">
                <div className="block-image">
                  <img src={solution2} alt="" />
                </div>
              </div>
            </div>

            <span id="proto_type_testing"></span>
            <div className="row align-items-center justify-content-center mb-50">
              <div className="col-lg-5 col-md-10 mobile-hd">
                <div className="block-image">
                  <img src={solution3} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">PROTO TYPE TESTING</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      MELZ is engaged in developing proto type testing machines
                      to assist and stimulate problems with real time
                      application conditions and environment
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      System to provide precise, repeatable test results for
                      production & manufacturing engineering
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Solutions embedded with data logging, report generation
                      and complete data acquisition (wired and wireless)
                      creating a permanent record confirming built specification
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Change management and revision history with time stamp
                      logging to enhance the product maturity and upgrade
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Design option for OBD tool interface and to assist Digital
                      Twin
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 desktop-hd mobile-mt">
                <div className="block-image">
                  <img src={solution3} alt="" />
                </div>
              </div>
            </div>

            <span id="portable_test_rigs"></span>
            <div className="row align-items-center justify-content-center mb-50">
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">PORTABLE TEST RIGS</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      An unique & Tailor made to meet customer specification and
                      test standard
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Configurable to validate performance and durability of DUT
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Deployed from stage wise inspection up to pre delivery
                      check
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Data Log with real time-stamping and Report generation
                      (Customized)
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Ease to test & validate BS VI, Hybrid and EV compliant
                      sensors/modules
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Embedded with high-end closed loop control system & Data
                      Acquisition (DAQ) systems
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      OEM asset to overcome future field failures, Scalable for
                      encrypted cloud interface there by assisting
                      implementation of Industry 4.0
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 mobile-mt">
                <div className="block-image">
                  <img src={solution4} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="line-one">
            <img src={line2} alt="line-shape" />
          </div>
          <div className="line-two">
            <img src={line1} alt="line-shape" />
          </div>
        </div>
      </section>

      {/* <section className="client-section">
        <div className="container">
          <div className="client-slider section-gap line-top">
            <Client />
          </div>
        </div>
      </section> */}

      <Footer />
    </Fragment>
  );
};

export default AutomatedTestingEquipments;
