import React, { Component, Fragment } from "react";
//import About from "./About";
import About1 from "../homethree/About";
//import Advancedtab from "./Advancedtab";
//import Service from "./Service";
//import Testimonials from "./Testimonials";
import Testimonials from "../hometwo/Testimonials";
//import Video from "./Video";
import Details from "../portfoliodetail/Details";
//import Teamblock from "../Team/Teamblock";

class Content extends Component {
  render() {
    return (
      <Fragment>
        <Details />
        {/* <About /> */}
        <About1 />
        {/* <Teamblock /> */}
        {/* <Service />
        <Advancedtab />
        <Video /> */}
        <Testimonials />
      </Fragment>
    );
  }
}

export default Content;
