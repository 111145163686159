import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Preloader
import Preloader from "./components/layouts/Preloader";
// Pages
import Home from "./components/pages/Home";
import Hometwo from "./components/pages/Hometwo";
import Homethree from "./components/pages/Homethree";
import About from "./components/pages/About";
import Bloggrid from "./components/pages/Bloggrid";
import Blogstandard from "./components/pages/Blogstandard";
import Blogdetail from "./components/pages/Blogdetail";
import Portfolio from "./components/pages/Portfolio";
import Portfoliotwo from "./components/pages/Portfoliotwo";
import Portfoliodetail from "./components/pages/Portfoliodetail";
import Service from "./components/pages/Services";
import Servicetwo from "./components/pages/Servicetwo";
import Servicedetail from "./components/pages/Servicedetail";
import Shop from "./components/pages/Shop";
import Productdetail from "./components/pages/Productdetail";
import Team from "./components/pages/Team";
import Teamdetail from "./components/pages/Teamdetail";
import Careers from "./components/pages/Careers";
import Contact from "./components/pages/Contact";
import Faq from "./components/pages/Faq";
import ScrollToTop from "./components/layouts/ScrollTop";
import BuildingManagement from "./components/pages/Building_management";
import Privacy_policy from "./components/pages/Privacy_policy";

import { aali_tm_moving_animation, wowJsAnimation } from "../src/utilits";
import AutomatedTestingEquipments from "./components/pages/Automated_testing_equipments";
import PageNotfound from "./components/pages/NotFound";
import FileTransfer from "./components/pages/FileTransfer";
import AssemblylineAutomation from "./components/pages/Assembly_line_automation";
import E_mobility_testing from "./components/pages/E_mobility_testing";
import AdvancedSpecialSystems from "./components/pages/Advanced_special_systems";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import YouTube from "./components/pages/Youtube";

export default () => {
  useEffect(() => {
    wowJsAnimation();
    aali_tm_moving_animation();
  });
  return (
    <Router basename={"/"}>
      <ScrollToTop />
      <Preloader />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/hometwo" component={Hometwo} />
        <Route path="/homethree" component={Homethree} />
        <Route path="/about" component={About} />
        <Route
          path="/automated_test_machines"
          component={AutomatedTestingEquipments}
        />
        <Route
          path="/assembly_line_automation"
          component={AssemblylineAutomation}
        />
        <Route
          path="/advanced_special_systems"
          component={AdvancedSpecialSystems}
        />
        <Route path="/e_mobility_testing" component={E_mobility_testing} />
        <Route path="/building_management" component={BuildingManagement} />
        <Route path="/events" component={Bloggrid} />
        <Route path="/blogstandard" component={Blogstandard} />
        <Route path="/eventdetail/:slug" component={Blogdetail} />
        <Route path="/gallery" component={Portfolio} />
        <Route path="/videos" component={YouTube} />
        <Route path="/portfoliotwo" component={Portfoliotwo} />
        <Route path="/portfoliodetail" component={Portfoliodetail} />
        <Route path="/services" component={Service} />
        <Route path="/servicetwo" component={Servicetwo} />
        <Route path="/servicedetail" component={Servicedetail} />
        <Route path="/shop" component={Shop} />
        <Route path="/shopdetail" component={Productdetail} />
        <Route path="/team" component={Team} />
        <Route path="/teamdetail" component={Teamdetail} />
        <Route path="/careers" component={Careers} />
        <Route path="/contact" component={Contact} />
        <Route path="/faq" component={Faq} />
        <Route path="/privacy_policy" component={Privacy_policy} />
        <Route path="/file_transfer/:slug" component={FileTransfer} />
        <Route path="*" component={PageNotfound} />
      </Switch>
    </Router>
  );
};
