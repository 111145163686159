import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Sidebar from "../../layouts/Blogsidebar";
// import Pagination from "../../layouts/Pagination";
import sanityClient from "../../../../src/client";

import img1 from "../../../assets/img/project/6.jpg";
import img2 from "../../../assets/img/project/66.jpg";
import img3 from "../../../assets/img/project/666.jpeg";
import under from "../../../assets/img/others/page-under-construction-icon.jpg";

// const blogblock = [
//   {
//     img: img1,
//     title: "How Performance Visiblety With GitLab CI And Hood",
//     text: "Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam raperiaeaqupsa quae",
//     postdate: "10 Aug 2022",
//     urltext: "Learn More",
//   },
//   {
//     img: img2,
//     title: "Inspired Design Decisions With Max Huber Turne",
//     text: "Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam raperiaeaqupsa quae",
//     postdate: "25 Aug 2021",
//     urltext: "Learn More",
//   },
//   {
//     img: img3,
//     title: "Mirage JS Deep Dive Unders Tanding Mirage JS Models",
//     text: "Sedut perspiciatis unde omnis nat erroroluptat accusantium laudantim totam raperiaeaqupsa quae",
//     postdate: "18 Aug 2020",
//     urltext: "Learn More",
//   },
// ];

const Content = () => {
  const [postdata, setPost] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
          title,
          slug,
          dis,
          mainImage{
            asset->{
            _id,
            url
          }
        },    
          categories,
          publishedAt          
    }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);

  return (
    <section className="blog-section section-gap-top">
      <div className="container">
        <img src={under} style={{ margin: "auto", display: "none" }} />
        <div
          className="row justify-content-center"
          style={{ display: "block" }}
        >
          <div className="col-lg-12">
            {/* Blog loop(Grid) */}
            <div className="blog-loop grid-blog row justify-content-center">
              {/* Single Post */}

              {!postdata ? <h1>No data</h1> : <></>}
              {postdata &&
                postdata.map((item, i) => (
                  <div
                    key={i}
                    className="col-lg-4 col-md-4 col-10 col-tiny-12 wow fadeInUp "
                    data-wow-delay="0.5s"
                  >
                    <div className="single-post-box">
                      <div className="post-thumb">
                        <img src={item.mainImage.asset.url} alt="" />
                      </div>
                      <div className="post-content">
                        <span className="post-date">
                          <i className="far fa-calendar-alt" />
                          {new Date(item.publishedAt).toDateString()}
                        </span>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span className="post-date">
                          <i className="far fa-tag" />
                          {item.categories}
                        </span>
                        <h3 className="title">
                          <Link to={`/eventdetail/${item.slug.current}`}>
                            {item.title.length <= 45
                              ? item.title
                              : item.title.substring(0, 45) + "..."}
                          </Link>
                        </h3>
                        <p>
                          {" "}
                          {!item.dis ? (
                            <>No Data</>
                          ) : item.dis.length <= 120 ? (
                            item.dis
                          ) : (
                            item.dis.substring(0, 120) + "..."
                          )}
                        </p>
                        <Link
                          to={`/eventdetail/${item.slug.current}`}
                          className="post-link"
                        >
                          Read more
                          <i className="far fa-long-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {/* Pagination */}
            {/* <Pagination /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
