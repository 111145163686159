import WOW from "wowjs";

export const wowJsAnimation = () => {
  if (typeof window !== "undefined") {
    window.WOW = require("wowjs");
  }
  new WOW.WOW().init();
};
// Moveing effect
export const aali_tm_moving_animation = () => {
  var detail = document.querySelectorAll(".moving_effect");
  var offset = 0;
  detail.forEach((element) => {
    var direction = element.getAttribute("data-direction");
    window.addEventListener("scroll", function () {
      offset = window.scrollY;
      var h = window.innerHeight;
      var i = element.getBoundingClientRect().top + window.scrollY - offset - h;
      if (element.getAttribute("data-reverse") === "yes") {
        i *= -1;
      }
      var x = direction === "x" ? (i * 70) / h : 0;
      var y = direction === "x" ? 0 : (i * 70) / h;
      if (element.getAttribute("data-reverse") === "yes") {
        i *= -1;
      }
      if (i * -1 < h + 300 && i < 300) {
        element.style.transform = `translate3d(${x}px,${y}px, 0px)`;
      }
    });
  });
};
