import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
// import Client from "../layouts/Client";
// import Content from "../sections/portfolio/Content";
import Cta from "../sections/homethree/Cta";
import YoutubeBlocks from "../sections/portfolio/YoutubeBlocks";

const YouTube = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>MELZ Indcosys | Videos</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Videos", bgimg: "video" }} />
      {/* <Content /> */}
      <YoutubeBlocks />
      <Cta />
      <Footer />
    </Fragment>
  );
};

export default YouTube;
