import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import img1 from "../../assets/img/slider/TEST RIG 1.jpg";
import img2 from "../../assets/img/slider/TEST RIG 2.jpg";
import img3 from "../../assets/img/slider/TEST RIG 3.jpg";
import img4 from "../../assets/img/slider/TEST RIG 4.jpg";
import img5 from "../../assets/img/slider/TEST RIG 5.jpg";
import img6 from "../../assets/img/slider/TEST RIG 6.jpg";

const clientslide = [
  { img: img1 },
  { img: img2 },
  { img: img3 },
  { img: img4 },
  { img: img5 },
  { img: img6 },
];

const settings = {
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 500,
  arrows: false,
  fade: false,
  dots: false,
  swipe: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const settings1 = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  arrows: false,
  fade: false,
  dots: false,
  swipe: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
class Slide extends Component {
  render() {
    return (
      <>
        {/* <div className="section-title text-center both-border ">
          <span className="title-tag">Our Valuable Clientele</span>
        </div> */}
        <span id="associations"></span>
        <Slider
          className="row align-items-center justify-content-between mb-50"
          id="clientSlider"
          {...settings}
        >
          {clientslide.map((item, i) => (
            <div key={i} className="col">
              <Link to="#" className="client-img d-block text-center slideIMG">
                <img src={item.img} alt="" />
              </Link>
            </div>
          ))}
        </Slider>
      </>
    );
  }
}

export default Slide;
