import React from "react";
import { Link } from "react-router-dom";

import illustration from "../../../assets/img/illustration/02.png";
import line1 from "../../../assets/img/lines/03.png";
import line2 from "../../../assets/img/lines/04.png";
import PRESENCEimg from "../../../assets/img/map/PRESENCE.png";

import sanityClient from "../../../../src/client";
import { useState } from "react";
import { useEffect } from "react";

function Whyus(props) {
  const [presence, setAllPresence] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "presence"]{
          district,
          direction,
          cname,
          designation,
          phone,
          email,
          publishedAt,
    }`
      )
      .then((data) => setAllPresence(data))
      .catch(console.error);
  }, []);
  return (
    <>
      <section className="wcu-section box-style mt-25">
        <div className="container-fluid" style={{ padding: "0" }}>
          <div className="wcu-inner wcu-p">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6">
                <div
                  className="wcu-image text-center text-lg-left wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="400ms"
                >
                  <img className="mapImg" src={PRESENCEimg} alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-10 job-list-section">
                <div className="section-title left-border mb-25">
                  <span className="title-tag">OUR PRESENCE</span>
                  <p>
                    MELZ being Customer centric company and strongly believes a
                    well satisfied customer as its real strength. To provide
                    quick after sales service MELZ have established support
                    offices at Gurugram – Nothern region, Pune – Western region,
                    Chennai and Bengaluru – South region.
                  </p>
                </div>
                <div className="job-lists">
                  {presence &&
                    presence.map((item, index) => (
                      <div className="single-job" key={index}>
                        <h4 className="title">
                          {item.district + " "}
                          <span className="job-time"> - {item.direction}</span>
                        </h4>
                        <p>
                          <b>{item.cname}</b>
                        </p>
                        <p>{item.designation}</p>
                        <p>
                          <b>Phone :</b>
                          {item.phone}
                        </p>
                        <p>
                          <b>Email &nbsp;: </b> {item.email}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <img src={line1} alt="shape" className="line-shape-one" />
            <img src={line2} alt="shape" className="line-shape-two" />
          </div>
        </div>
      </section>
      <section className="contact-section contact-page section-gap-top">
        <div className="container">
          <div className="contact-info">
            <div className="row justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="contact-map ht-75">
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31326.34033945679!2d76.910965!3d11.054175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba858b0334e472d%3A0xbc2d2ed714ad3883!2sMELZ%20INDCOSYS%20SOLUTIONS!5e0!3m2!1sen!2sus!4v1680585143150!5m2!1sen!2sus"
                    style={{ border: 0 }}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex={0}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-10 order-1 order-lg-2">
                <div className="contact-info-content contact-b">
                  <div className="section-title left-border mb-40">
                    <span className="title-tag">Get In Touch</span>
                    <h2 className="title contact-h2">
                      MELZ Indcosys Solutions LLP
                    </h2>
                  </div>

                  <ul>
                    {/* <li className="phone">
                    <Link
                      to={{ pathname: "tel:+9104222960830" }}
                      target="_blank"
                    >
                      <i className="far fa-phone" />
                      0422-2960830
                    </Link>
                  </li> */}

                    <li>
                      <i className="far fa-mobile" />
                      <Link
                        to={{ pathname: "tel:+917867016420" }}
                        target="_blank"
                      >
                        <b>Mobile :</b> +91 78670 16420, 78670 16430
                      </Link>
                    </li>
                    <li style={{ display: "flex" }}>
                      <i className="far fa-envelope-open" />
                      <b>General & HR : </b>
                      <p>
                        <Link
                          to={{ pathname: "mailto:info@melzindcosys.com" }}
                          target="_blank"
                        >
                          {" "}
                          info@melzindcosys.com
                        </Link>{" "}
                        ,{" "}
                        <Link
                          to={{ pathname: "mailto:hrd@melzindcosys.com" }}
                          target="_blank"
                        >
                          {" "}
                          hrd@melzindcosys.com
                        </Link>
                      </p>
                    </li>
                    <li>
                      <i className="far fa-envelope-open" />
                      <Link to="#">
                        <b>Projects Enquiry :</b> project@melzindcosys.com
                      </Link>
                    </li>
                    <li>
                      <i className="far fa-envelope-open" />
                      <Link to="#">
                        <b>Sales Enquiry :</b> sales@melzindcosys.com
                      </Link>
                    </li>

                    <li>
                      <i className="far fa-map-marker-alt" />
                      <b>Location: </b> 334/1E, Karthik Nagar, Agarwal School
                      Road,
                      <br />
                      Kalappanaicken Palayam, Somayam Palayam(PO),
                      Coimbatore-641108
                    </li>
                  </ul>
                  <ul className="social-links cnt-social">
                    <li>
                      <Link
                        to={{
                          pathname:
                            "https://www.facebook.com/profile.php?id=100063937543308",
                        }}
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname:
                            "https://www.youtube.com/channel/UCmkDRulWggxtogDhEMNnrxA",
                        }}
                        target="_blank"
                      >
                        <i className="fab fa-youtube" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname:
                            "https://in.linkedin.com/pub/melz-indcosys-solutions-llp/101/a73/988",
                        }}
                        target="_blank"
                      >
                        <i className="fab fa-linkedin" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Whyus;
