import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
// import Client from "../layouts/Client";
// import { Tab, Nav } from "react-bootstrap";

import line1 from "../../assets/img/lines/11.png";
import line2 from "../../assets/img/lines/12.png";

import solution2 from "../../assets/img/solution/solu2.jpg";
import solution3 from "../../assets/img/solution/solu3.jpg";
import solution4 from "../../assets/img/solution/solu4.jpg";
import Slide from "../layouts/Slide";
const BuildingManagement = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>MELZ Indcosys | Building Management</title>
        <meta name="description" content="Building Management" />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Building Management" }} />
      <section className="service-section grey-bg service-line-shape section-gap">
        <div className="container">
          {/* Section Title */}
          <div className="section-title text-center both-border mb-50 mobile-mb">
            <span className="title-tag">Solutions </span>
            <h2 className="title">BUILDING MANAGEMENT</h2>
          </div>

          <div className="tab-text-block left-image with-left-circle">
            <div className="row align-items-center justify-content-center mb-50">
              <div className="col-lg-5 col-md-10 mobile-hd">
                <div className="block-image">
                  <img src={solution2} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">BMCS Product</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" /> BMS
                    </li>
                    <li>
                      <i className="fas fa-check" /> HMI Touch Panel
                    </li>
                    <li>
                      <i className="fas fa-check" /> DALI Controller
                    </li>
                    <li>
                      <i className="fas fa-check" /> Room Controller
                    </li>
                    <li>
                      <i className="fas fa-check" /> Gateway Interface
                    </li>
                    <li>
                      <i className="fas fa-check" /> I/O Module
                    </li>
                    <li>
                      <i className="fas fa-check" /> Local Controller
                    </li>
                    <li>
                      <i className="fas fa-check" /> Automation Server
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 desktop-hd mobile-mt">
                <div className="block-image">
                  <img src={solution2} alt="" />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center mb-50">
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">Delta - Loytec Modular DDC</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" /> Multi protocol Application
                      with data points of different technologies:
                      CEA-709(Lonmark System), BACnet, KNX, Modbus, M-Bus,
                      Enocean
                    </li>
                    <li>
                      <i className="fas fa-check" /> Expandable upto 480 Points
                    </li>
                    <li>
                      <i className="fas fa-check" /> Dual switched or separated
                      Ethernet Ports
                    </li>
                    <li>
                      <i className="fas fa-check" /> Redundant Ring Topology
                    </li>
                    <li>
                      <i className="fas fa-check" /> Built in third party
                      integration Port
                    </li>
                    <li>
                      <i className="fas fa-check" /> Memory expansion with micro
                      SD Card
                    </li>
                    <li>
                      <i className="fas fa-check" /> Integrated OPC Server
                      (XML-DA/UA)
                    </li>
                    <li>
                      <i className="fas fa-check" /> Stores Graphical User
                      Interface
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 mobile-mt">
                <div className="block-image">
                  <img src={solution2} alt="" />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center mb-50">
              <div className="col-lg-5 col-md-10 mobile-hd ">
                <div className="block-image">
                  <img src={solution3} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">Delta - Loytec DCCs Controller</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      DDC's are on Protocol i.e. BAC net/IP, LonmarkI/P &
                      Lonmark TP/TF-10
                    </li>
                    <li>
                      <i className="fas fa-check" /> Loytech DDC are of
                      following types: Modular, Standalone
                    </li>
                    <li>
                      <i className="fas fa-check" /> All controllers/ Module
                      have built in LCD display
                    </li>
                    <li>
                      <i className="fas fa-check" /> Controller's LCD Display is
                      password Protected
                    </li>
                    <li>
                      <i className="fas fa-check" /> Authorizes Person can
                      Monitor/Control & Set Point change from controller itself
                    </li>
                    <li>
                      <i className="fas fa-check" /> Integrated OPC server
                      (XML-DA/UA)
                    </li>
                    <li>
                      <i className="fas fa-check" /> Allow Alarming, scheduling
                      & Trending(AST) features
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 desktop-hd mobile-mt">
                <div className="block-image">
                  <img src={solution3} alt="" />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center mb-50">
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">Delta - Loytec Standalone DDC</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      Multiple open protocol availablity: BACnet/IP, Lon/IP &
                      Lon TP/FT
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Dual switched or seperated ethernet ports
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Redundant ring topology possible
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Allow AST features
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Event driven e-mail notification
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Integrated OPC server (XML-DA / UA)
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Stores graphical user interface
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10">
                <div className="block-image">
                  <img src={solution4} alt="" />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center mb-50">
              <div className="col-lg-5 col-md-10 mobile-hd ">
                <div className="block-image">
                  <img src={solution2} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">
                    Delta - Loytec Lighting Controller DDC
                  </h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      Multiprotocol Application with data points of different
                      technologies: CEA - 709 (Lonmark System), BACnet, KNX,
                      Modbus, Enocean
                    </li>
                    <li>
                      <i className="fas fa-check" /> Two variants - Expandable
                      upto 480 Points & Modular
                    </li>
                    <li>
                      <i className="fas fa-check" /> Loytech can be used for -
                      Lighting Control, Sun blind control with Angle Adjustment,
                      Occupancy detection, Window Monitoring
                    </li>
                    <li>
                      <i className="fas fa-check" /> All other features as of
                      L-INX cntrollers
                    </li>
                    <li>
                      <i className="fas fa-check" /> Constant Light Control
                    </li>
                    <li>
                      <i className="fas fa-check" /> Advanced DALI functions
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 desktop-hd mobile-mt">
                <div className="block-image">
                  <img src={solution2} alt="" />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center mb-50">
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">Delta - Loytec VAV DDC</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      IP Based
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      It detects malfuctioning devices like a blocked damper
                      actuator, a stuck reheat valve a dead series fan, etc,.
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Networking via redundant IP network
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Alarming, Scheduling & Trending (AST)
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 mobile-mt">
                <div className="block-image">
                  <img src={solution4} alt="" />
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-center mb-50">
              <div className="col-lg-5 col-md-10 mobile-hd">
                <div className="block-image">
                  <img src={solution2} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">Loytech Gateways</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      Universal in nature
                    </li>
                    <li>
                      <i className="fas fa-check" /> Supports BACnet/IP,
                      BACnet/MSTP, LON IP, LON TP/FT 10, Modbus, KNX. Used for
                      Lighting Control & related functions
                    </li>
                    <li>
                      <i className="fas fa-check" /> Event Driven e-mail
                    </li>
                    <li>
                      <i className="fas fa-check" /> Built in OPC XML-DA and OPC
                      UA server
                    </li>
                    <li>
                      <i className="fas fa-check" /> Memory expansion by micro
                      SD card
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 desktop-hd mobile-mt">
                <div className="block-image">
                  <img src={solution2} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="line-one">
            <img src={line2} alt="line-shape" />
          </div>
          <div className="line-two">
            <img src={line1} alt="line-shape" />
          </div>
        </div>
      </section>

      <section className="client-section">
        <div className="container">
          <div className="client-slider section-gap line-top">
            <Slide />
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default BuildingManagement;
