import React from "react";

import thumb from "../../../assets/img/banner/MELZ OFF.jpg";

// import dcuImg from "../../../assets/img/others/brochureIcon.jpg";
import dcuImg2 from "../../../assets/img/others/brochureIcon2.jpg";

// import Profile from "../../../assets/img/pdf/MELZ Profile.pdf";
import MelzBrochure from "../../../assets/img/pdf/MELZ Brochure.pdf";
// import training from "../../../assets/img/pdf/training.pdf";
import { Link } from "react-router-dom";

function Details(props) {
  return (
    <section className="project-details section-gap">
      <div className="container">
        <div className="project-thumb">
          <img
            src={thumb}
            alt="office"
            style={{ filter: "drop-shadow(2px 4px 6px gray)" }}
          />
        </div>
        <div className="project-content mt-60">
          <div className="row">
            <div className="col-lg-2 order-1 order-lg-2"></div>
            <div className="col-lg-8 order-1 order-lg-2 wow bounceIn">
              <div className="content pIntent pCent">
                <h2
                  className="animate__animated animate__bounce wow fadeInLeft"
                  data-wow-duration="1s"
                >
                  MELZ Indcosys Solutions
                </h2>
                <p>
                  MELZ is a Turn-Key Automation Solution Company located in
                  Industrial city of Coimbatore, Tamilnadu - India.
                </p>
                <p>
                  Since inception MELZ is dedicated to cater the operational
                  needs of the emerging technologies in Industrial & Machine
                  Automation with advanced technologies.
                </p>
                <p>
                  Over the years MELZ has proved its strength by successful
                  design, development, manufacturing & supply of various simpler
                  to complex TEST RIGS for Product Reliability, Durability &
                  Performance in Automotive, Aviation, Defense, Food, Oil & Gas
                  Verticals.
                </p>
                <p>
                  MELZ believes in building our reputation on - Technical
                  expertise, Professional approach, Commercial integrity and
                  affordable solutions.
                </p>
                <Link
                  className="main-btn main-btn-3  wow fadeInUp"
                  target="_blank"
                  to={MelzBrochure}
                >
                  Brochure
                </Link>
              </div>
            </div>
            <div className="col-lg-2 order-2 order-lg-2"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Details;
