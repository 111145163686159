import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

import Client from "../layouts/Client";
import Content from "../sections/blogdetail/Content";
import Cta from "../sections/homethree/Cta";

class Blogdetail extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>MELZ Indcosys | News & Events details</title>
          <meta name="description" content="#" />
        </MetaTags>
        <Header />

        <Content />
        {/* <section className="client-section">
          <div className="container">
            <div className="client-slider section-gap">
              <Client />
            </div>
          </div>
        </section> */}
        <Cta />
        <Footer />
      </Fragment>
    );
  }
}

export default Blogdetail;
