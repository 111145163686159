import React from "react";
import { Link } from "react-router-dom";

import line1 from "../../../assets/img/lines/12.png";
import line2 from "../../../assets/img/lines/11.png";

import ser11 from "../../../assets/img/service/ser11.jpg";
import ser12 from "../../../assets/img/service/ser12.jpg";
import ser9 from "../../../assets/img/service/ser9.jpg";
import ser10 from "../../../assets/img/service/ser10.jpg";

const serviceblock = [
  {
    icon: "flaticon-chart",
    title: "Automated Test Machines",
    url: "/automated_test_machines",
    img: ser9,
  },
  {
    icon: "flaticon-graph-1",
    title: "Assembly Line Automation",
    url: "/assembly_line_automation",
    img: ser10,
  },
  {
    icon: "flaticon-diagram",
    title: "Advanced Special Systems",
    url: "/advanced_special_systems",
    img: ser11,
  },
  {
    icon: "flaticon-money",
    title: "e-Mobility Testing ",
    url: "/e_mobility_testing",
    img: ser12,
  },
];

function Solutions(props) {
  return (
    <section className="service-section shape-style-two service-line-shape section-gap pt-0">
      <div className="container">
        {/* Section Title */}
        <div className="section-title text-center both-border mb-0">
          <span className="title-tag"> SOLUTIONS</span>
          {/* <h2 className="title">
            We Provide Most Exclusive <br /> Service For Business
          </h2> */}
        </div>
        {/* Services Boxes */}
        <div className="row service-boxes justify-content-center">
          {serviceblock.map((item, i) => (
            <div
              key={i}
              className="col-lg-3 col-sm-6 col-10 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="0.5s"
            >
              <div className="service-box-two text-center">
                <Link to={item.url}>
                  <div className="icon">
                    <img src={item.img} width="100px" />
                  </div>
                </Link>

                <h3
                  className={item.url === "/e_mobility_testing" ? "pb-26" : ""}
                >
                  <Link to={item.url}>{item.title}</Link>
                </h3>
                <Link to={item.url} className="service-link">
                  <i className="fal fa-arrow-right" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="line-one">
        <img src={line1} alt="line-shape" />
      </div>
      <div className="line-two">
        <img src={line2} alt="line-shape" />
      </div> */}
    </section>
  );
}

export default Solutions;
