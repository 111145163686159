import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

import illustration from "../../../assets/img/others/Mix1.jpg";

function Faqs(props) {
  return (
    <section className="faq-section section-gap with-illustration with-shape p-t-0 grey-bg">
      <div className="container">
        <div className="faq-illustration-img">
          <img src={illustration} alt="illustration" />
        </div>
        <div className="row justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="faq-content">
              <div className="section-title mb-40 left-border">
                {/* <span className="title-tag wow slideInLeft">
                  Why We Are Best ?
                </span> */}
                <h2 className="title wow fadeInUp">
                  Reasons For Choosing MELZ
                </h2>
              </div>
              {/* FAQ LOOP */}
              <Accordion
                defaultActiveKey="0"
                className="faq-loop"
                id="faqAccordion"
              >
                <Card>
                  <Accordion.Collapse eventKey="0" className="collapseparent">
                    <Card.Body>
                      Every Test machine, Industry and Process demands for
                      customization with respect to the defining standards and
                      testing requirements. Unlike "one size fits all", MELZ
                      specialized in offering Custom built solutions which are
                      scalable and adaptable systems
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      Customization
                      <span className="icons">
                        <i className="far fa-plus" />
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
                <Card>
                  <Accordion.Collapse eventKey="2" className="collapseparent">
                    <Card.Body>
                      MELZ solutions are simplified with integrating and
                      handshaking various technological developments available
                      in Industrial Automation. We believe on simple & effective
                      technology to install as plug and play, upgrade on
                      modularity, enhance and migrate to future advancement in
                      system and engineering.
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      Simplicity
                      <span className="icons">
                        <i className="far fa-plus" />
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
                <Card>
                  <Accordion.Collapse eventKey="1" className="collapseparent">
                    <Card.Body>
                      Testing systems demand everything to work and work
                      reliably. MELZ offer the most reliable, viable & effective
                      solutions designed for lasting operations. With the
                      expertise and strong domain knowledge in industrial
                      automation and control, all our solutions are designed,
                      engineered, built, and guaranteed to work as a complete
                      integrated system to perform 24x7 non stop test sequences
                      and longer duration life validation.
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      Reliability
                      <span className="icons">
                        <i className="far fa-plus" />
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>

                <Card>
                  <Accordion.Collapse eventKey="3" className="collapseparent">
                    <Card.Body>
                      Customer support is more than a responsibility, it's an
                      extension of our commitment to provide the best, easiest
                      customer experience in a timely manner to ensure the
                      highest Customer Satisfaction. MELZ has unparallel focus
                      on the providing after sales support pan India and
                      overseas.
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                      Support
                      <span className="icons">
                        <i className="far fa-plus" />
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                </Card>
              </Accordion>
              {/* End Faq LOOP */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
