import React, { Component, Fragment, useRef, useState } from "react";
// import Jobcategory from "./Jobcategory";
// import Joblist from "./Joblist";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

import "react-toastify/dist/ReactToastify.css";
import { LuRefreshCw } from "react-icons/lu";

const Content = () => {
  const [sFile, setsFile] = useState(0);

  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setsFile(event.target.files[0].size);
    //setHandleFile(fileUploaded);
    if (fileUploaded) {
      document.getElementById("btnUpl").style.display = "none";
      document.getElementById("btnUpl").value = "SDS";
      document.getElementById("upFile").style.display = "block";
    }
  };
  const form4 = useRef();
  const [valA, setValA] = useState(Math.floor(Math.random() * 10));
  const [valB, setValB] = useState(Math.floor(Math.random() * 10));
  const RefreshC = () => {
    setValA(Math.floor(Math.random() * 10));
    setValB(Math.floor(Math.random() * 10));
  };

  const [email2, setEmail2] = useState("");
  function isValidEmail(email2) {
    return /\S+@\S+\.\S+/.test(email2);
  }
  const handleChange2 = (event) => {
    setEmail2(event.target.value);
  };
  const sendEmail4 = (e) => {
    e.preventDefault();
    const av = document.getElementById("jValue").value;
    const bv = document.getElementById("kValue").value;
    const cv = document.getElementById("lValue").value;
    if (isValidEmail(email2)) {
      if (sFile < 256000) {
        if (parseInt(av) + parseInt(bv) === parseInt(cv)) {
          emailjs
            .sendForm(
              "service_1zojb3b",
              "template_dd2yune",
              form4.current,
              "3j9OCBNg4gqsWKwIr"
            )
            .then(
              (result) => {
                console.log(result.text);
                toast.success(
                  "Thanks, your enquiry has been submitted successfully!"
                );
              },
              (error) => {
                console.log(error.text);
              }
            );
          setValA(Math.floor(Math.random() * 10));
          setValB(Math.floor(Math.random() * 10));
          e.target.reset();
        } else {
          toast.error("Error Captcha!");
          return;
        }
      } else {
        toast.error("Error Resume upload below 250Kb");
        return;
      }
    } else {
      toast.error("Error Email is invalid!");
      return;
    }
  };
  return (
    <Fragment>
      {/* <Jobcategory />
                <Joblist /> */}
      <section className="service-section grey-bg service-line-shape section-gap careerBG">
        <div className="container">
          {/* Section Title */}
          <div className="section-title text-center both-border mb-50">
            <span className="title-tag">Careers </span>
            <p className="">
              MELZ believes in employee enrichment with global technological
              updates, we work with the redefined mission to uplift the young &
              dynamic engineers to enhance the demanding development. Right
              decision taken at right time is what will be counted for years. If
              yes we would be happy to hear from you. Please fill the form below
              & upload your resume.
            </p>
          </div>
          <div className="contact-form grey-bg1">
            <div className="row no-gutters justify-content-center p-t-b-30">
              <div className="col-10">
                <div className="section-title text-center mb-40">
                  <h2 className="title">Join our team</h2>
                </div>
                <form ref={form4} onSubmit={sendEmail4}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-group mb-30 select">
                        <select required name="j_title">
                          <option value="">Select Title *</option>
                          <option value="job">JOB</option>
                          <option value="industrial project">
                            INDUSTRIAL PROJECT
                          </option>
                          <option value="inplant training">
                            {" "}
                            INPLANT TRAINING
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group mb-30">
                        <input
                          type="text"
                          name="f_name"
                          placeholder="Full Name *"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group mb-30">
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange2}
                          placeholder="Email ID *"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="input-group mb-30">
                        <input
                          type="text"
                          placeholder="Phone Number"
                          pattern="\d{10}"
                          title="Please enter exactly 10 digits Phone number"
                          maxLength="10"
                          name="p_no"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group mb-30">
                        <input
                          name="edu"
                          type="text"
                          placeholder="Education Qualification *"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group mb-30">
                        {/* <input
                          type="text"
                          placeholder="Upload your CV *"
                          onClick={fileClick}
                          required
                          accept="application/pdf"
                        />
                        <input
                          type="file"
                          id="getFile"
                          hidden
                          name=""
                          required
                        /> */}
                        <input
                          type="text"
                          onClick={handleClick}
                          className=""
                          id="btnUpl"
                          name="dsds"
                          required
                          placeholder="Upload your CV (Below 250Kb onlys )*"
                          style={{ textAlign: "left" }}
                        />

                        <input
                          type="file"
                          id="upFile"
                          required
                          ref={hiddenFileInput}
                          onChange={handleChange}
                          style={{ display: "none" }}
                          accept="application/msword, application/pdf"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group mb-30">
                        <input
                          name="exp"
                          type="text"
                          placeholder="Experiences *"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="input-group mb-30 select">
                        <select required name="int">
                          <option value="">Select interested In *</option>
                          <option value="EMPLOYMENT">EMPLOYMENT</option>
                          <option value="INDUSTRIAL PROJECT WORK">
                            INDUSTRIAL PROJECT WORK
                          </option>
                          <option value="TRAINING CERTIFICATION COURSE">
                            TRAINING CERTIFICATION COURSE
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-group mb-30 textarea">
                        <textarea
                          name="add"
                          placeholder="Address"
                          required=""
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4 mb-30">
                      <div className="cpatchaDiv">
                        <input
                          type="number"
                          readOnly
                          value={valA}
                          id="jValue"
                        />{" "}
                        <span>+</span>
                        <input
                          type="number"
                          readOnly
                          value={valB}
                          id="kValue"
                        />{" "}
                        <span>=</span>
                        <div className="inputTotal">
                          <input type="number" id="lValue" required />
                        </div>
                        <LuRefreshCw className="refIc" onClick={RefreshC} />
                      </div>
                    </div>
                    <div className="col-8"></div>

                    <div className="col-12 text-center">
                      <button type="submit" className="main-btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Content;
