import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Offcanvas from "./Offcanvas";

const SuportBtn = () => {
  const [classmethod, setClassmethod] = useState(false);
  //const [classmethod1, setClassmethod1] = useState(matches);
  const canvasToggles = () => {
    setClassmethod(!classmethod);
  };

  return (
    <>
      <Link
        className="main-btn main-btn-3 d-view"
        to="#"
        onClick={canvasToggles}
      >
        Enquiry
      </Link>

      <button onClick={canvasToggles} className="sideDiv">
        Enquiry
      </button>

      <div
        className={
          classmethod === true
            ? "offcanvas-wrapper show-offcanvas enquiryCls"
            : "offcanvas-wrapper enquiryCls"
        }
      >
        <div
          className={
            classmethod === true
              ? "offcanvas-overly show-overly"
              : "offcanvas-overly"
          }
          onClick={canvasToggles}
        />
        <div className="offcanvas-widget">
          <Link to="#" className="offcanvas-close" onClick={canvasToggles}>
            <i className="fal fa-times" />
          </Link>
          <Offcanvas />
        </div>
      </div>
    </>
  );
};

export default SuportBtn;
