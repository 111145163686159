import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
// import Client from "../layouts/Client";
import Content from "../sections/contact/Content";
import Whyus from "../sections/home/Whyus";
// import Cta from "../sections/homethree/Cta";

class Contact extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Melzindcosys | Contact Us</title>
          <meta name="description" content="#" />
        </MetaTags>
        <Header />
        <Breadcrumbs
          breadcrumb={{ pagename: "Contact Us", bgimg: "contact" }}
        />
        <Content />
        <Whyus />
        {/* <Cta /> */}
        <Footer />
      </Fragment>
    );
  }
}

export default Contact;
