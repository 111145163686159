import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

import { useEffect } from "react";
import sanityClient from "../../../../src/client";

function Blocks(props) {
  //   const [filterProject, setFilterProject] = useState(projects);

  //   const handleClick = (name) => {
  //     let newFilterProjects = [];
  //     if (name === "All Project") {
  //       newFilterProjects = projects;
  //     } else {
  //       newFilterProjects = projects.filter((project, i) =>
  //         project.origin.includes(name)
  //       );
  //     }

  //     const index = filtersbtn
  //       .map(function (e) {
  //         return e.name;
  //       })
  //       .indexOf(name);
  //     if (index) {
  //       filtersbtn.forEach((item) => {
  //         item.isActive = false;
  //       });
  //       filtersbtn[index].isActive = true;
  //     }
  //     setFilterProject(newFilterProjects);
  //   };
  const [apiimage, setApiimage] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "gallery"]{
          name,
          mainImage{
            asset->{
            _id,
            url
          }
        }         
    }`
      )
      .then((data) => setApiimage(data))
      .catch(console.error);
  }, []);

  const [viewimg, setViewimg] = useState(false);
  const [addimg, setAddimg] = useState("");
  const imgFullsize = (img) => {
    setAddimg(img);
    setViewimg(!viewimg);
    // console.log(img);
  };

  return (
    <>
      {viewimg && (
        <div className="fixed-div w-full h-screen fixed top-0 left-0 bg-slate-100 z-[10000] flex justify-center items-center wow fadeInUp">
          <div className="fixed-child-div w-[90%] md:w-[100%] h-[500px]  m-auto wow fadeInUp">
            <img
              className=" max-h-[500px] md:h-[inherit] m-auto shadow-2xl"
              src={addimg}
              alt="img1"
            />
            <AiOutlineClose
              onClick={() => setViewimg(!viewimg)}
              className=" absolute right-12 top-10 md:right-40 md:top-20 text-[3rem] cursor-pointer"
            />
          </div>
        </div>
      )}

      <section className="project-section p-t-0 mobile-pb">
        <div className="container">
          {/* Project Boxes */}
          <div className="project-boxes project-isotope mt-60 gallery-clm">
            {apiimage &&
              apiimage.map((item, index) => (
                <div
                  key={index}
                  className="isotope-item w-100 wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <div className="project-box hover-style">
                    <div className="project-thumb ">
                      <img src={item.mainImage.asset.url} />
                    </div>
                    <div className="project-desc text-center">
                      <h4>
                        <Link
                          to="#"
                          onClick={() => imgFullsize(item.mainImage.asset.url)}
                        >
                          {item.name}
                        </Link>
                      </h4>

                      <Link
                        to="#"
                        onClick={() => imgFullsize(item.mainImage.asset.url)}
                        className="project-link"
                      >
                        <i className="fal fa-eye" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Blocks;
