import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

import "react-toastify/dist/ReactToastify.css";
import { LuRefreshCw } from "react-icons/lu";

// import illustration from "../../../assets/img/illustration/man-with-earth-02.png";

const Content = () => {
  const form3 = useRef();
  const [valA, setValA] = useState(Math.floor(Math.random() * 10));
  const [valB, setValB] = useState(Math.floor(Math.random() * 10));
  const RefreshC = () => {
    setValA(Math.floor(Math.random() * 10));
    setValB(Math.floor(Math.random() * 10));
  };
  const [email, setEmail] = useState("");
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const sendEmail3 = (e) => {
    e.preventDefault();
    const av = document.getElementById("gValue").value;
    const bv = document.getElementById("hValue").value;
    const cv = document.getElementById("iValue").value;

    if (isValidEmail(email)) {
      if (parseInt(av) + parseInt(bv) === parseInt(cv)) {
        emailjs
          .sendForm(
            "service_1zojb3b",
            "template_dijz7qf",
            form3.current,
            "3j9OCBNg4gqsWKwIr"
          )
          .then(
            (result) => {
              console.log(result.text);
              toast.success(
                "Thanks, your enquiry has been submitted successfully!"
              );
            },
            (error) => {
              console.log(error.text);
            }
          );
        setValA(Math.floor(Math.random() * 10));
        setValB(Math.floor(Math.random() * 10));
        e.target.reset();
      } else {
        toast.error("Error Captcha!");
        return;
      }
    } else {
      toast.error("Error Email is invalid!");
      return;
    }
  };
  return (
    <section
      className="contact-section contact-page section-gap-top"
      id="contactEnquiry"
    >
      <div className="container">
        <div className="contact-form grey-bg">
          <div className="row no-gutters justify-content-center">
            <div className="col-10">
              <div className="section-title text-center mb-40">
                <h2 className="title">Feel Free to Contact Us</h2>
              </div>
              <form ref={form3} onSubmit={sendEmail3}>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="input-group mb-30">
                      <input
                        type="text"
                        name="f_name"
                        placeholder="Full Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-group mb-30">
                      <input
                        type="text"
                        name="j_title"
                        placeholder="Job Title"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-group mb-30">
                      <input
                        type="text"
                        name="c_name"
                        placeholder="Company Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-group mb-30">
                      <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        placeholder="Email ID"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-group mb-30">
                      <input
                        type="text"
                        placeholder="Mobile No"
                        pattern="\d{10}"
                        title="Please enter exactly 10 digits Phone number"
                        maxLength="10"
                        name="p_no"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-group mb-30 select">
                      <select required name="int">
                        <option value="">Subject</option>
                        <option value="AMC">AMC</option>
                        <option value="NON AMC">NON AMC</option>
                        <option value="NEW ENQUIRY">NEW ENQUIRY</option>
                        <option value="OTHERS">OTHERS</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                    <div className="input-group textarea mb-30">
                      <textarea
                        required
                        placeholder="Write Address"
                        defaultValue={""}
                      />
                    </div>
                  </div> */}
                  <div className="col-lg-12">
                    <div className="input-group textarea mb-30">
                      <textarea
                        name="msg"
                        placeholder="Write Message"
                        defaultValue={""}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4 mb-30">
                    <div className="cpatchaDiv">
                      <input type="number" readOnly value={valA} id="gValue" />{" "}
                      <span>+</span>
                      <input
                        type="number"
                        readOnly
                        value={valB}
                        id="hValue"
                      />{" "}
                      <span>=</span>
                      <div className="inputTotal">
                        <input type="number" id="iValue" required />
                      </div>
                      <LuRefreshCw className="refIc" onClick={RefreshC} />
                    </div>
                  </div>
                  <div className="col-8"></div>
                  <div className="col-12 text-center">
                    <button type="submit" className="main-btn">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
