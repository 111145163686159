import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Client from "../layouts/Client";
import Content from "../sections/service/Content";
import Cta from "../sections/homethree/Cta";

class Service extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>MELZ Indcosys | Services</title>
          <meta name="description" content="#" />
        </MetaTags>
        <Header />
        <Breadcrumbs breadcrumb={{ pagename: "Services", bgimg: "services" }} />
        <Content />
        <Cta />
        <Footer />
      </Fragment>
    );
  }
}

export default Service;
