import React, { Component } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/img/logo-2.png";
import shape1 from "../../assets/img/lines/01.png";
import shape2 from "../../assets/img/lines/02.png";
import { HashLink } from "react-router-hash-link";
import { FiMail, FiMapPin, FiPhone } from "react-icons/fi";
import { FaMobileAlt } from "react-icons/fa";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="footer-widget">
            <div className="row">
              {/* <div className="col-lg-4 col-sm-5 order-1">
                <div className="widget site-info-widget">
                  <div className="footer-logo">
                    <img src={logo} alt="" />
                  </div>
                  <p>
                    Power of choice is untrammelled &amp; when nothing prevents
                    our being able
                  </p>
                  <ul className="social-links">
                    <li>
                      <Link
                        to={{
                          pathname:
                            "https://www.facebook.com/profile.php?id=100063937543308",
                        }}
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname:
                            "https://www.youtube.com/channel/UCmkDRulWggxtogDhEMNnrxA",
                        }}
                        target="_blank"
                      >
                        <i className="fab fa-youtube" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname:
                            "https://in.linkedin.com/pub/melz-indcosys-solutions-llp/101/a73/988",
                        }}
                        target="_blank"
                      >
                        <i className="fab fa-linkedin" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-sm-7 order-2">
                <div className="widget newsletter-widget">
                  <h4 className="widget-title">Need Any Consultations</h4>
                  <div className="newsletter-form">
                    <form action="#">
                      <input
                        type="email"
                        required
                        placeholder="Enter Your Email"
                      />
                      <button type="submit" className="main-btn">
                        Submit Now
                      </button>
                    </form>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-3 col-sm-6 order-3">
                <div className="widget nav-widget">
                  <h4 className="widget-title">Quick Links</h4>
                  <ul>
                    <li>
                      <HashLink smooth to="/about#melzteam">
                        Team - MELZ
                      </HashLink>
                    </li>
                    <li>
                      <Link to="/events">Events</Link>
                    </li>
                    <li>
                      <Link to="/careers">Careers</Link>
                    </li>
                    <li>
                      <Link to="/videos">Videos</Link>
                    </li>
                    {/* <li>
                      <HashLink to="/about#testimonials">Testimonials</HashLink>
                    </li> */}
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-sm-6 order-lg-6 order-6">
                <div className="widget iconCl">
                  <h4 className="widget-title">MELZ INDCOSYS SOLUTIONS LLP </h4>
                  <p className="pb-10">
                    <FiMapPin />
                    334/1E, Karthik Nagar, Agarwal School Road, Kalappanaicken
                    Palayam, Somayam Palayam (PO), Coimbatore-641108, Tamilnadu
                    - INDIA.
                  </p>{" "}
                  <p>
                    <FiPhone /> 0422-2960830
                  </p>
                  <p>
                    {" "}
                    <FaMobileAlt /> +91 78670 16420, 78670 16430
                  </p>
                  <p className="pb-10">
                    {" "}
                    <FiMail /> info@melzindcosys.com
                  </p>
                  <ul className="social-links cnt-social ">
                    <li>
                      <Link
                        to={{
                          pathname:
                            "https://www.facebook.com/profile.php?id=100063937543308",
                        }}
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname:
                            "https://www.youtube.com/channel/UCmkDRulWggxtogDhEMNnrxA",
                        }}
                        target="_blank"
                      >
                        <i className="fab fa-youtube" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname:
                            "https://in.linkedin.com/pub/melz-indcosys-solutions-llp/101/a73/988",
                        }}
                        target="_blank"
                      >
                        <i className="fab fa-linkedin" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 order-lg-6 order-6">
                <div className="widget ">
                  <h4 className="widget-title">Location</h4>
                  <div className="">
                    <iframe
                      title="map"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31326.34033945679!2d76.910965!3d11.054175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba858b0334e472d%3A0xbc2d2ed714ad3883!2sMELZ%20INDCOSYS%20SOLUTIONS!5e0!3m2!1sen!2sus!4v1680585143150!5m2!1sen!2sus"
                      style={{ border: 0 }}
                      width="100%"
                      height="240px"
                      allowFullScreen
                      aria-hidden="false"
                      tabIndex={0}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <p className="copyright-text">
              <span>
                Copyright <Link to="#">MELZ indcosys solutions</Link> 2015 | All
                Rights Reserved
              </span>
              <span>
                {" "}
                <Link to="/privacy_policy">Privacy & Disclaimer Policy</Link>
              </span>
            </p>
            <Link to="#" className="back-to-top" onClick={scrollToTop}>
              <i className="far fa-angle-up" />
            </Link>
          </div>
        </div>
        {/* Lines */}
        <img src={shape1} alt="line" className="line-one" />
        <img src={shape2} alt="line" className="line-two" />
      </footer>
    );
  }
}

export default Footer;
