import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import { Link } from "react-router-dom";

class Privacy_policy extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>MELZ Indcosys | PRIVACY & DISCLAIMER POLICY</title>
          <meta name="description" content="#" />
        </MetaTags>
        <Header />
        <Breadcrumbs breadcrumb={{ pagename: "PRIVACY & DISCLAIMER POLICY" }} />
        <section className="skill-section skill-section-two">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-12 col-md-12">
                {/* Skill Text Block */}
                <div className="skill-text">
                  <p>
                    This privacy policy defines protocol on how MELZ Indcosys
                    Solutions LLP collects uses, maintains and protects any
                    information that you give to MELZ Indcosys Solutions LLP
                    when you use this website
                    <Link to="/"> : http://www.melzindcosys.com.</Link>
                  </p>
                  <p>
                    MELZ Indcosys Solutions LLP is committed to ensuring that
                    your privacy is protected. User can be assured that it will
                    only be used in accordance with this privacy statement. MELZ
                    Indcosys Solutions LLP may change this policy from time to
                    time by updating this page. We encourage Users to check this
                    policy page frequently to ensure that you are happy with any
                    changes on how we are helping to protect the personal
                    information.
                  </p>
                  <p>
                    <small>
                      <b>This policy is effective from 1st August, 2015.</b>
                    </small>
                  </p>
                  <div className="section-title  left-border">
                    <span className="title-tag">
                      Personal Identification Information
                    </span>
                  </div>
                  <p>
                    We may collect personal identification information from
                    Users like Name & Job details, Contact information including
                    mobile & email address, Demographic information such as
                    postcode, preferences and interests, Project based solutions
                    and requirement details & any other information relevant to
                    customer surveys and/or offers. Non – personal
                    identification information like browser name, hardware,
                    software platforms, and the Internet service provider’s
                    details from the user connection to our Site
                  </p>
                  <div className="section-title  left-border">
                    <span className="title-tag">Security Definition</span>
                  </div>
                  <p>
                    MELZ Indcosys is committed to ensuring that your
                    information’s are safe & secure. To protect against
                    unauthorised access, alteration, disclosure or destruction
                    we have put in place suitable physical, electronic and
                    managerial procedures to safeguard and secure the
                    information we collect online.
                  </p>
                  <p>
                    We do not sell, trade, distribute or lease your personal
                    information to other third parties unless we have your
                    permission or are required by law to do so. We may share
                    your generic demographic information not linked to any
                    personal identification information regarding visitors and
                    users with our business partners, trusted affiliates and
                    advertisers.
                  </p>
                  <div className="section-title  left-border">
                    <span className="title-tag">
                      Intended Usage of Information
                    </span>
                  </div>
                  <p>
                    We require this information to understand your needs and
                    provide you with a better service and in particular for the
                    internal record keeping with correct and most appropriate
                    details. The information will help us to improve our
                    products and services and support customer needs more
                    efficiently. To circulate any promotional email about new
                    products, special offers or other information which we think
                    you may find interesting. We may use the information to
                    customise the website for personalization usages.
                  </p>
                  <div className="section-title left-border">
                    <span className="title-tag">Links to Other Websites</span>
                  </div>
                  <p>
                    Our website may contain links to enable you to visit other
                    websites of interest easily. However, once you have used
                    these links to leave our site, you should note that we do
                    not have any control over that other website. Therefore, we
                    cannot be responsible for the protection and privacy of any
                    information which you provide whilst visiting such sites and
                    such sites are not governed by this privacy statement. You
                    should exercise caution and look at the privacy statement
                    applicable to that website in question.
                  </p>
                  <div className="section-title  left-border">
                    <span className="title-tag">Email disclaimer</span>
                  </div>
                  <p>
                    Any email and files transmitted from MELZ Indcosys Solutions
                    LLP are confidential and intended solely for the use of the
                    individual or entity to whom they are addressed. If you have
                    received an email in error, please reply to the sender by
                    reply email, delete the email from your system and destroy
                    any copies. Any views or opinions expressed in any email are
                    solely those of the author and do not necessarily represent
                    those of MELZ Indcosys Solutions LLP. For the avoidance of
                    doubt, please note that MELZ Indcosys Solutions LLP accepts
                    no responsibility for any email sent by MELZ Indcosys
                    Solutions LLP employees that are of a personal nature or in
                    breach of any law, Act, statute or regulation applicable in
                    either the country of origin or receipt. All MELZ Indcosys
                    Solutions LLP emails and any attachments has been scanned to
                    detect viruses. MELZ Indcosys accepts no liability for loss
                    or damage caused by received emails or attachments due to
                    viruses, interference, corruption or unauthorized access.
                  </p>
                  <div className="section-title  left-border">
                    <span className="title-tag">Contacting MELZ</span>
                  </div>
                  <p>
                    If you believe that any information we are holding on you is
                    incorrect or incomplete, please write to or email us as soon
                    to the below email. We will promptly correct any information
                    found to be incorrect. If you have any questions about this
                    Privacy Policy, the practices of this site, or your dealings
                    with this site, please contact us{" "}
                    <Link
                      to={{ pathname: "mailto:info@melzindcosys.com" }}
                      target="_blank"
                    >
                      {" "}
                      info@melzindcosys.com
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default Privacy_policy;
