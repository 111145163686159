import React, { Component, useEffect, useState } from "react";

const Preloader = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 3000);
  }, [loading]);

  const preloaderload = loading ? " d-none" : " ";
  return (
    <div id="preloader" className={`${preloaderload}`}>
      <div className="loader-cubes">
        <div className="loader-cube1 loader-cube"></div>
        <div className="loader-cube2 loader-cube"></div>
        <div className="loader-cube4 loader-cube"></div>
        <div className="loader-cube3 loader-cube"></div>
      </div>
    </div>
  );
};

export default Preloader;
