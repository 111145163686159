import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import img1 from "../../../assets/img/banner/03.jpg";
import img2 from "../../../assets/img/banner/02.jpg";
import img3 from "../../../assets/img/banner/05.jpg";
import img4 from "../../../assets/img/banner/04.jpg";

const bannerpost = [
  {
    img: img1,
    tag: "Makes IT Possible",
    title: "Making Testing a Reality with enhanced Automation",
    btntext: "/automated_test_machines",
    btntext1: "Learn More",
  },
  {
    img: img2,
    tag: "Makes IT Possible",
    title: "Custom Built Design with integrated IoT",
    btntext: "/automated_test_machines",
    btntext1: "Learn More",
  },
  {
    img: img4,
    tag: "Makes IT Possible",
    title: "Turn-key Test Solutions for e-mobility",
    btntext: "e_mobility_testing",
    btntext1: "Learn More",
  },
  {
    img: img3,
    tag: "Makes IT Possible",
    title: "Advanced Tactical Solutions for Product & Process Validation",
    btntext: "/assembly_line_automation",
    btntext1: "Learn More",
  },
];
const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="slick-arrow next-arrow" onClick={onClick}>
      <i className="fas fa-chevron-right" />
    </button>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="slick-arrow prev-arrow" onClick={onClick}>
      <i className="fas fa-chevron-left" />
    </button>
  );
};

function Banner(props) {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 300,
    arrows: true,
    fade: false,
    dots: true,
    swipe: true,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
        },
      },
    ],
  };
  return (
    <section className="banner-section ">
      <Slider className="banner-slider" id="bannerSlider" {...settings}>
        {bannerpost.map((item, i) => (
          <div key={i}>
            <div
              className="single-banner"
              style={{ backgroundImage: "url(" + item.img + ")" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="banner-content">
                      {/* <span
                        className="promo-text"
                        data-animation="fadeInDown"
                        data-delay="0.8s"
                      >
                        {item.tag}
                      </span> */}
                      <h1 data-animation="fadeInUp" data-delay="1s">
                        {item.title}
                      </h1>
                      <ul className="btn-wrap">
                        <li data-animation="fadeInLeft" data-delay="1.2s">
                          <Link
                            to={item.btntext}
                            className="main-btn main-btn-4"
                          >
                            {item.btntext1}
                          </Link>
                        </li>
                        {/* <li data-animation="fadeInRight" data-delay="1.4s">
                          <Link to="/service" className="main-btn main-btn-2">
                            {item.btntext1}
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="banner-shapes">
                <div className="one" />
                <div className="two" />
                <div className="three" />
                <div className="four" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
}

export default Banner;
