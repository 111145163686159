import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

const navigationmenu = [
  {
    id: 1,
    linkText: "Home",
    link: "/",
    child: false,
  },
  {
    id: 2,
    linkText: "About",
    link: "/about",
    child: false,
    submenu: [
      {
        id: 21,
        link: "#",
        linkText: "Profile",
      },
      {
        id: 22,
        link: "#",
        linkText: "News & Event",
      },
    ],
  },
  {
    id: 3,
    linkText: "Solutions",
    link: "#",
    child: true,
    submenu: [
      {
        id: 31,
        link: "/industrial_automation",
        linkText: "Industrial Automation",
      },
      {
        id: 32,
        link: "/building_management",
        linkText: "Building Management",
      },
      // {
      //   id: 34,
      //   link: "/industrial_automation",
      //   linkText: "Energy Management",
      // },
      {
        id: 33,
        link: "/industrial_automation",
        linkText: "Mechanical Solutions",
      },
    ],
    supchild: true,
    supsubmenu: [
      {
        id: 31,
        link: "/industrial_automation",
        linkText: "Industrial Automation",
      },
      {
        id: 32,
        link: "/building_management",
        linkText: "Building Management",
      },

      {
        id: 33,
        link: "/industrial_automation",
        linkText: "Mechanical Solutions",
      },
    ],
  },
  {
    id: 4,
    linkText: "Services",
    link: "/services",
  },

  {
    id: 5,
    linkText: "Gallery",
    link: "/gallery",
    child: false,
    submenu: [
      {
        id: 51,
        link: "/portfolio",
        linkText: "Portfolio",
      },
      {
        id: 52,
        link: "/portfoliotwo",
        linkText: "Portfolio Two",
      },
      {
        id: 53,
        link: "/portfoliodetail",
        linkText: "Portfolio Details",
      },
    ],
  },
  {
    id: 8,
    linkText: "Events",
    link: "events",
  },

  {
    id: 6,
    linkText: "Career",
    link: "/careers",
    child: false,
    submenu: [
      {
        id: 61,
        link: "/shop",
        linkText: "Shop",
      },
      {
        id: 62,
        link: "/shopdetail",
        linkText: "Product Details",
      },
    ],
  },
  {
    id: 7,
    linkText: "Contact",
    link: "/contact",
  },
];

const Navmenu = () => {
  const location = useLocation();
  // console.log(location);
  const getNextSibling = (elem, selector) => {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;
    // If there's no selector, return the first sibling
    if (!selector) return sibling;
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
  };

  const triggerChild = (e) => {
    let subMenu = "";
    let supsubMenu = "";
    subMenu =
      getNextSibling(e.target, ".submenu") !== undefined
        ? getNextSibling(e.target, ".submenu")
        : null;
    supsubMenu =
      getNextSibling(e.target, ".submenu") !== undefined
        ? getNextSibling(e.target, ".submenu")
        : null;
    if (subMenu !== null && subMenu !== undefined && subMenu !== "") {
      subMenu.classList = subMenu.classList.contains("d-block")
        ? "submenu"
        : "submenu d-block";
    }

    if (supsubMenu !== null && supsubMenu !== undefined && supsubMenu !== "") {
      supsubMenu.classList = supsubMenu.classList.contains("d-block")
        ? "submenu d-block"
        : "submenu ";
    }
  };

  return (
    <ul>
      <li className="">
        <Link to="/" className={location.pathname === "/" ? "activeCL" : ""}>
          Home
        </Link>
      </li>
      <li className="">
        <Link
          to="/about"
          className={location.pathname === "/about" ? "activeCL" : ""}
        >
          About
        </Link>
      </li>
      <li className="has-submemu" onClick={triggerChild}>
        <Link
          onClick={(e) => e.preventDefault()}
          to="/"
          className={
            location.pathname === "/automated_test_machines" ||
            location.pathname === "/assembly_line_automation" ||
            location.pathname === "/advanced_special_systems" ||
            location.pathname === "/e_mobility_testing"
              ? "activeCL"
              : ""
          }
        >
          Solutions
        </Link>
        <span className="dd-trigger">
          <i className="fal fa-angle-down"></i>
        </span>

        <ul className="submenu submenuHr" role="menu">
          <li className="has-submemuh">
            <Link className="lH20" to="/automated_test_machines">
              Automated Test Machines
            </Link>

            <ul className="sup-menu submenu submenu1">
              <li>
                <HashLink
                  smooth
                  to="/automated_test_machines#product_test_benches"
                >
                  Product Test Benches & Rigs
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/automated_test_machines#process_validation_machines"
                >
                  Process Validation Machines
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/automated_test_machines#proto_type_testing"
                >
                  Proto Type Testing
                </HashLink>
              </li>
              <li>
                <HashLink
                  smooth
                  to="/automated_test_machines#portable_test_rigs"
                >
                  Portable Test Rigs
                </HashLink>
              </li>
            </ul>
          </li>
          <li className="has-submemuh">
            <Link to="/assembly_line_automation">Assembly Line Automation</Link>
            <ul className="sup-menu submenu submenu2">
              <li>
                <HashLink to="/assembly_line_automation#ILTS" smooth>
                  In-line Test Systems
                </HashLink>
              </li>
              <li>
                <HashLink to="/assembly_line_automation#EOL-TS" smooth>
                  End of line Test Benches{" "}
                </HashLink>
              </li>
              <li>
                <HashLink to="/assembly_line_automation#ARS" smooth>
                  Automated Robotics Systems
                </HashLink>
              </li>
              <li>
                <HashLink to="/assembly_line_automation#ATS" smooth>
                  Assembly Traceability Systems
                </HashLink>
              </li>
            </ul>
          </li>
          <li className="has-submemuh">
            <Link to="/advanced_special_systems">Advanced Special Systems</Link>
          </li>
          {/* <li className="has-submemuh">
              <Link to="#">Environmental testing</Link>
              <ul className="sup-menu submenu submenu3">
                <li>
                  <Link to="/">Hot & cold chamber</Link>
                </li>
                <li>
                  <Link to="/">Thermal shock</Link>
                </li>
                <li>
                  <Link to="/">Rain & dust testing</Link>
                </li>
                <li>
                  <Link to="/">Ipxx </Link>
                </li>
                <li>
                  <Link to="/">Drop test</Link>
                </li>
                <li>
                  <Link to="/">Bump & vibration</Link>
                </li>
              </ul>
            </li> */}
          <li className="has-submemuh">
            <Link to="/e_mobility_testing">e-Mobility Testing</Link>
            <ul className="sup-menu submenu submenu4 ">
              <li>
                <HashLink to="/e_mobility_testing#e-PDT" smooth>
                  {" "}
                  Performance & Durability Testing
                </HashLink>
              </li>
              <li>
                <HashLink to="/e_mobility_testing#ILEL-TS" smooth>
                  {" "}
                  In-Line & End Of Line Testing
                </HashLink>
              </li>
              <li>
                <HashLink to="/e_mobility_testing#e-VLT" smooth>
                  e-Vehicle Level Validation{" "}
                </HashLink>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li className="">
        <Link
          to="/services"
          className={location.pathname === "/services" ? "activeCL" : ""}
        >
          Services
        </Link>
      </li>
      <li className="">
        <Link
          to="/gallery"
          className={location.pathname === "/gallery" ? "activeCL" : ""}
        >
          Gallery
        </Link>
      </li>
      {/* <li className="">
          <Link to="/events">Events</Link>
        </li>
        <li className="">
          <Link to="/careers">Careers</Link>
        </li> */}
      <li className="">
        <Link
          to="/contact"
          className={location.pathname === "/contact" ? "activeCL" : ""}
        >
          Contact
        </Link>
      </li>
    </ul>
  );
};

export default Navmenu;
