import React from "react";
import { Link } from "react-router-dom";

import line1 from "../../../assets/img/lines/12.png";
import line2 from "../../../assets/img/lines/11.png";

import ser1 from "../../../assets/img/service/ser1.jpg";
import ser2 from "../../../assets/img/service/ser2.jpg";
import ser3 from "../../../assets/img/service/ser3.jpg";
import ser4 from "../../../assets/img/service/ser4.jpg";
import ser5 from "../../../assets/img/service/ser5.jpg";
import ser6 from "../../../assets/img/service/ser6.jpg";
import ser7 from "../../../assets/img/service/ser7.jpg";
import ser8 from "../../../assets/img/service/ser8.jpg";
import { HashLink } from "react-router-hash-link";

const serviceblock = [
  {
    icon: "flaticon-graph-1",
    title: "Installation testing & Commisioning",
    img: ser1,
  },
  {
    icon: "flaticon-chart",
    title: "Proposal & Project Engineering",
    img: ser2,
  },

  {
    icon: "flaticon-diagram",
    title: "Proto type Engineering & Estimation",
    img: ser3,
  },
  {
    icon: "flaticon-money",
    title: "Project Planning & Cost budgeting",
    img: ser4,
  },
  // {
  //   icon: "flaticon-diamond",
  //   title: "Approvals & Qualification Centre",
  //   img: ser5,
  // },
  {
    icon: "flaticon-teamwork-1",
    title: "Annual Maintenance Contract - AMC",
    img: ser6,
  },
  {
    icon: "flaticon-setup",
    title: "Training & Certification Courses",
    img: ser7,
  },
  {
    icon: "flaticon-setup",
    title: "Call our support team 24/7",
    img: ser8,
    hlink: "/contact",
  },
];

function Service(props) {
  return (
    <section className="service-section shape-style-two service-line-shape section-gap grey-bg">
      <div className="container">
        {/* Section Title */}
        <div className="section-title text-center both-border mb-50">
          <span className="title-tag"> SERVICES</span>
          {/* <h2 className="title">
            We Provide Most Exclusive <br /> Service For Business
          </h2> */}
        </div>
        {/* Services Boxes */}
        <div className="row service-boxes justify-content-center">
          {serviceblock.map((item, i) => (
            <div
              key={i}
              className="col-lg-3 col-sm-6 col-10 wow fadeInUp"
              data-wow-duration="1500ms"
              data-wow-delay="0.5s"
            >
              <div className="service-box-two text-center">
                <HashLink
                  to={item.hlink ? "/contact#contactEnquiry" : "/services"}
                >
                  <div className="icon">
                    <img src={item.img} width="100px" />
                  </div>
                </HashLink>
                <h3>
                  <HashLink
                    to={item.hlink ? "/contact#contactEnquiry" : "/services"}
                  >
                    {item.title}
                  </HashLink>
                </h3>
                <HashLink
                  to={item.hlink ? "/contact#contactEnquiry" : "/services"}
                  smooth
                  className="service-link"
                >
                  <i className="fal fa-arrow-right" />
                </HashLink>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="line-one">
        <img src={line1} alt="line-shape" />
      </div>
      <div className="line-two">
        <img src={line2} alt="line-shape" />
      </div>
    </section>
  );
}

export default Service;
