import React, { Component, Fragment } from "react";
import Client from "../../layouts/Client";
import About from "./About";
import Banner from "./Banner";
//import Blog from "./Blog";
//import Counter from "./Counter";
//import Features from "./Features";
import Service from "../hometwo/Service";
//import Skills from "./Skills";
//import Team from "./Team";
//import Testimonials from "../hometwo/Testimonials";
import Video from "./Video";
//import Whyus from "./Whyus";
import Faqs from "../homethree/Faqs";
import Solutions from "../hometwo/Solutions";
//import Workingprocess from "../hometwo/Workingprocess";

class Content extends Component {
  render() {
    return (
      <Fragment>
        <Banner />
        <About />
        <Solutions />
        <Service />

        <Faqs />
        <Video />
        {/* <Counter />
        <Workingprocess /> */}

        {/* <Testimonials />
        <Blog /> */}
        <section className="client-section">
          <div className="container">
            <div className="client-slider section-gap line-bottom">
              <Client />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Content;
