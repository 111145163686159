import React from "react";
import { Link } from "react-router-dom";

import breadcrumbimg from "../../assets/img/banner/about.jpg";
import ate from "../../assets/img/banner/ate.jpg";
import ala from "../../assets/img/banner/ala.jpg";
import ass from "../../assets/img/banner/ass.jpg";
import em from "../../assets/img/banner/em.jpg";
import gallery from "../../assets/img/banner/gallery.jpg";
import career from "../../assets/img/banner/services.jpg";
import services from "../../assets/img/banner/ab2contact.jpg";
import about from "../../assets/img/banner/breadcrumb1.jpg";
import contact from "../../assets/img/banner/contact1.jpg";
import video from "../../assets/img/banner/gal.jpg";

const Breadcrumbs = (props) => {
  return (
    <>
      <section
        className="breadcrumb-section bg-img-c"
        style={(function () {
          switch (props.breadcrumb.bgimg) {
            case "ate":
              return { backgroundImage: "url(" + ate + ")" };
            case "ala":
              return { backgroundImage: "url(" + ala + ")" };
            case "ass":
              return { backgroundImage: "url(" + ass + ")" };
            case "em":
              return { backgroundImage: "url(" + em + ")" };
            case "gallery":
              return { backgroundImage: "url(" + gallery + ")" };
            case "services":
              return { backgroundImage: "url(" + services + ")" };
            case "career":
              return { backgroundImage: "url(" + career + ")" };
            case "about":
              return { backgroundImage: "url(" + about + ")" };
            case "contact":
              return { backgroundImage: "url(" + contact + ")" };
            case "video":
              return { backgroundImage: "url(" + video + ")" };
            default:
              return { backgroundImage: "url(" + breadcrumbimg + ")" };
          }
        })()}
        // style={{
        //   backgroundImage:
        //     "url(" + ate + ")",
        // }}
      >
        <div className="container">
          <div className="breadcrumb-text">
            <h1
              className={
                props.breadcrumb.pagename ? "page-title" : "page-title p-33"
              }
            >
              {props.breadcrumb.pagename}
            </h1>
            {/* <h1 className="page-title">{props.breadcrumb.pagename}</h1> */}
            <ul>
              <li>
                <Link to="/">Home </Link>
              </li>
              <li>{props.breadcrumb.pagename}</li>
            </ul>
          </div>
        </div>
        <div className="breadcrumb-shapes">
          <div className="one" />
          <div className="two" />
          <div className="three" />
        </div>
      </section>
    </>
  );
};

export default Breadcrumbs;
