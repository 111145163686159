import React, { useState } from "react";

import { useEffect } from "react";
import sanityClient from "../../../client";
import BlockContent from "@sanity/block-content-to-react";
import Iframe from "react-iframe";

function YoutubeBlocks(props) {
  const [apiimage, setApiimage] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "youtube"]{
          url,                 
    }`
      )
      .then((data) => setApiimage(data))
      .catch(console.error);
  }, []);

  return (
    <>
      <section className="project-section  mobile-pb">
        <div className="container ">
          {/* Project Boxes */}
          <div className="row">
            {apiimage &&
              apiimage.map((item, index) => (
                <div
                  key={index}
                  className="wow fadeInUp col-md-6 p-10 pb-30"
                  data-wow-delay="0.5s"
                >
                  <Iframe
                    url={item.url.replace(/youtu.be/g, "youtube.com/embed")}
                    width="100%"
                    height="320px"
                    id=""
                    className=""
                    display="block"
                    position="relative"
                  />
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default YoutubeBlocks;
