import React from "react";
//import { Link } from "react-router-dom";

//import img2 from "../../../assets/img/others/mv.jpg";
import img3 from "../../../assets/img/others/profile.jpg";

function About(props) {
  return (
    <section className="about-section-three section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10 order-2 order-lg-1">
            <div className="about-text-three">
              <div className="section-title left-border mb-20">
                <span className="title-tag">Vision</span>
              </div>
              <p className="mb-25">
                To become a world class leader in providing refined solutions to
                reduce risk with strategic intelligence and applied tactical
                engineering.
              </p>
              <div className="section-title left-border mb-20">
                <span className="title-tag">Mission</span>
              </div>
              <p className="mb-25">
                With highly crafted precise engineering design & quality we
                excel to offer the future technology based industrial automation
                solutions for testing, validation, life time simulation systems
                with unique custom build configuration and artificial
                intelligence enabled special machineries.
              </p>
              <div className="section-title left-border mb-20">
                <span className="title-tag">Quality Policy</span>
              </div>
              <p className="mb-25">
                To determine and deliver the world class Industrial Control
                System Solutions embedded with high Quality in terms of
                Engineering, Technology, Safety, Security and Reliability. The
                Company is dedicated to ensure that its products & services
                fully satisfy the requirements of the industry, projects and
                customers at all times based on continual improvement.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-10 order-1 order-lg-2">
            <div className="about-tile-gallery">
              <img
                src={img3}
                alt=""
                className="image-one wow fadeInRight"
                data-wow-duration="1500ms"
                data-wow-delay="400ms"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
