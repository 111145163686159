import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import img1 from "../../assets/img/clients/01.png";
import img2 from "../../assets/img/clients/02.png";
import img3 from "../../assets/img/clients/03.png";
import img4 from "../../assets/img/clients/04.png";
import img5 from "../../assets/img/clients/05.png";
import img6 from "../../assets/img/clients/06.png";
import img7 from "../../assets/img/clients/07.png";
//import img8 from "../../assets/img/clients/08.png";
import img9 from "../../assets/img/clients/09.png";
import img10 from "../../assets/img/clients/10.png";
import img11 from "../../assets/img/clients/11.png";
import img12 from "../../assets/img/clients/12.png";
import img13 from "../../assets/img/clients/13.png";
import img14 from "../../assets/img/clients/14.png";
import img15 from "../../assets/img/clients/15.png";
import img16 from "../../assets/img/clients/16.png";
import img20 from "../../assets/img/clients/20.png";
import img21 from "../../assets/img/clients/21.png";
import img22 from "../../assets/img/clients/22.png";
import img23 from "../../assets/img/clients/23.png";
import img24 from "../../assets/img/clients/24.png";
import img25 from "../../assets/img/clients/25.png";
import img26 from "../../assets/img/clients/26.png";
import img27 from "../../assets/img/clients/27.png";
import img28 from "../../assets/img/clients/28.png";
import img29 from "../../assets/img/clients/29.png";
import img30 from "../../assets/img/clients/30.png";
import img31 from "../../assets/img/clients/31.png";
import img32 from "../../assets/img/clients/32.png";
import img33 from "../../assets/img/clients/33.png";
import img34 from "../../assets/img/clients/34.png";
import img50 from "../../assets/img/clients/50.png";
import img51 from "../../assets/img/clients/51.png";
import a1 from "../../assets/img/clients/a1.png";
import a2 from "../../assets/img/clients/a2.png";

const clientslide = [
  { img: img2 },
  { img: img21 },
  { img: img5 },
  { img: img15 },
  { img: img32 },
  { img: img29 },
  { img: img22 },
  { img: img3 },
  { img: img9 },
  { img: img20 },
  { img: img30 },
  { img: img1 },
  { img: img11 },
  { img: img31 },
  { img: img4 },
  { img: img24 },
  { img: img13 },
  { img: img6 },
  { img: img12 },
  { img: img16 },
  { img: img23 },
  { img: img27 },
  { img: img25 },
  { img: img26 },
  { img: img28 },
  { img: img10 },
  { img: img14 },
  { img: img7 },
  { img: img33 },
  { img: img34 },
  { img: img50 },
  { img: img51 },
];

const settings = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  speed: 500,
  arrows: false,
  fade: false,
  dots: false,
  swipe: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const settings1 = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 500,
  speed: 500,
  arrows: false,
  fade: false,
  dots: false,
  swipe: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
class Client extends Component {
  render() {
    return (
      <>
        <div className="section-title text-center both-border ">
          <span className="title-tag"> Clientele</span>
        </div>
        <span id="associations"></span>
        <Slider
          className="row align-items-center justify-content-between mb-50"
          id="clientSlider"
          {...settings}
        >
          {clientslide.map((item, i) => (
            <div key={i} className="col">
              <Link to="#" className="client-img d-block text-center">
                <img src={item.img} alt="" />
              </Link>
            </div>
          ))}
        </Slider>

        <div className="container">
          <div className="section-title text-center both-border ">
            <span className="title-tag"> Associations</span>
          </div>
          <div className="associations">
            <Link
              to={{ pathname: "https://hornerautomation.com/india-2/" }}
              target="_blank"
            >
              <img src={a1} alt="" />
            </Link>
            <div className="lineSM"></div>
            <Link
              to={{ pathname: "https://www.festo.com/in/en/" }}
              target="_blank"
            >
              <img src={a2} alt="" />
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default Client;
