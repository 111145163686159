import React from "react";
import { Link } from "react-router-dom";

import illustration from "../../../assets/img/others/melz solution.jpg";

function About(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="row no-gutters justify-content-lg-end justify-content-center ">
          <div className="col-lg-6 col-md-10">
            <div className="about-text wow fadeInUp">
              <div className="section-title left-border mb-25">
                <span className="title-tag wow slideInLeft">About Us</span>
                <h2 className="title wow fadeInUp">MELZ Indcosys Solutions</h2>
              </div>
              <p className="mb-25">
                MELZ is a Turn-Key Automation Solution Company located in
                Industrial city of Coimbatore, Tamilnadu - India.
              </p>
              <p className="mb-25">
                Since inception MELZ is dedicated to cater the operational needs
                of the emerging technologies in Industrial & Machine Automation
                with advanced technologies.
              </p>

              <Link to="/about" className="main-btn">
                Learn More
              </Link>
            </div>
          </div>
        </div>
        <div className="illustration-img ">
          <img src={illustration} alt="ABOUT_SEO" />
        </div>
      </div>
    </section>
  );
}

export default About;
