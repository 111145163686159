import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import sanityClient from "../../../../src/client";

import img1 from "../../../assets/img/team/01.jpg";
import img2 from "../../../assets/img/team/02.jpg";
import img3 from "../../../assets/img/team/03.jpg";
import img4 from "../../../assets/img/team/04.jpg";
import img5 from "../../../assets/img/team/10.jpg";
import img6 from "../../../assets/img/team/11.jpg";
import img7 from "../../../assets/img/team/12.jpg";
import img8 from "../../../assets/img/team/13.jpg";
import teamlogo from "../../../assets/img/team-logo.png";

const teamblock = [
  { img: img1, name: "Name 1", post: "Business Developer" },
  { img: img2, name: "Name 2", post: "Business Developer" },
  { img: img3, name: "Name 3", post: "Business Developer" },
  { img: img4, name: "Name 4", post: "Business Developer" },
  { img: img5, name: "Name 5", post: "Business Developer" },
  { img: img6, name: "Name 6", post: "Business Developer" },
  { img: img7, name: "Name 7", post: "Business Developer" },
  { img: img8, name: "Name 8", post: "Business Developer" },
];
function Teamblock(props) {
  const [team, setTeam] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "ourteam"]{
          mainImage{
            asset->{
            _id,
            url
          }
        }         
    }`
      )
      .then((data) => setTeam(data))
      .catch(console.error);
  }, []);
  return (
    <>
      <span id="melzteam"></span>
      <section className="team-section section-gap section-gap-b pb-0">
        <div className="container">
          {/* Section Title */}
          <div className="section-title mb-40 both-border text-center">
            <span className="title-tag">MELZ Team</span>
            <h2 className="title">
              We Have An Exclusive <br /> Team Member
            </h2>
          </div>
          {/* Team Boxes */}
          <div className="row team-members justify-content-center">
            <div className="col-md-12">
              {team &&
                team.map((item, i) => (
                  <img
                    key={i}
                    className="teamImg"
                    src={item.mainImage.asset.url}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Teamblock;
