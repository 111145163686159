import React, { Component, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../layouts/Blogsidebar";

import blogbanner from "../../../assets/img/project/666.jpeg";

import Breadcrumbs from "../../layouts/Breadcrumbs";

import BlockContent from "@sanity/block-content-to-react";
//import { PortableText } from "@portabletext/react";
import sanityClient from "../../../../src/client";

const Contentblg = () => {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();
  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
            _id,
            url
          }
        },
          categories,
          publishedAt,
          body
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);
  if (!postData) return <div>Loading...</div>;
  return (
    <>
      <Breadcrumbs breadcrumb={{ pagename: "Blog Detail" }} />
      <section className="blog-section section-gap-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              {/* <PortableText value={postData.body} /> */}

              {/* Blog Details */}
              <div className="post-details-wrap">
                <div className="post-thumb post-img-full">
                  <img src={postData.mainImage.asset.url} alt="" />
                </div>
                <div className="post-meta">
                  <ul>
                    <li>
                      <i className="far fa-user" />
                      <Link to="#">Melz</Link>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" />
                      <Link to="#">
                        {new Date(postData.publishedAt).toDateString()}
                      </Link>
                    </li>
                    <li>
                      <i className="far fa-tag" />
                      <Link to="#">{postData.categories}</Link>
                    </li>
                  </ul>
                </div>

                <div className="post-content">
                  <h3 className="title">{postData.title}</h3>
                  <BlockContent
                    blocks={postData.body}
                    projectId="5zqnxyu9"
                    dataset="production"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-8">
              {/* sidebar */}
              <Sidebar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contentblg;
