import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
// import Client from "../layouts/Client";
// import { Tab, Nav } from "react-bootstrap";

import line1 from "../../assets/img/lines/11.png";
import line2 from "../../assets/img/lines/12.png";

import solution3 from "../../assets/img/solution/solu9.jpg";
// import Slide from "../layouts/Slide";
const AdvancedSpecialSystems = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>MELZ Indcosys | Advanced Special Systems</title>
        <meta name="description" content="Industrial Automation" />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "", bgimg: "ass" }} />
      <section className="service-section grey-bg service-line-shape section-gap">
        <div className="container">
          {/* Section Title */}
          <div className="section-title text-center both-border mb-50 mobile-mb">
            <span className="title-tag">Solutions </span>
            <h2 className="title">Advanced Special Systems</h2>
          </div>

          <div className="tab-text-block left-image with-left-circle">
            <span id="proto_type_testing"></span>
            <div className="row align-items-center justify-content-center mb-50">
              <div className="col-lg-5 col-md-10 mobile-hd">
                <div className="block-image">
                  <img src={solution3} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">ENVIRONMENTAL TESTING</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      With rising demand for system integration of environmental
                      testing with special and custom built test rigs, MELZ has
                      successfully designed and commissioned Advanced Special
                      System with integrated Environmental Testing
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      ASS-ET capable of meeting rugged requirements in Highly
                      Accelerated Life Testing (HALT), Highly Accelerated Stress
                      Screening (HASS), High Altitude, Vibration stress testing,
                      Thermal cycling, Temperature Stress, Humidity Stress
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Other Special system includes of Hot & Cold Testing, Leak
                      testing, Rain & Dust Testing, Corrosion Testing, IPXX
                      Testing, Drop Test, Bump Test, Burst Test Rigs, Impulse
                      Test Rig, CO2 & H2 Testing, Fatigue Testing – Linear &
                      Rotary and Burn-in Chamber
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Systems are designed to compliance with standards of ASTM,
                      EIA-364, IEC 60068-2, ISTA, JESD22, MIL-STD-202,
                      MIL-STD-331, MIL-STD-810, MIL-STD-883, SAEJ1455
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 desktop-hd mobile-mt">
                <div className="block-image">
                  <img src={solution3} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="line-one">
            <img src={line2} alt="line-shape" />
          </div>
          <div className="line-two">
            <img src={line1} alt="line-shape" />
          </div>
        </div>
      </section>

      {/* <section className="client-section">
        <div className="container">
          <div className="client-slider section-gap line-top">
            <Slide />
          </div>
        </div>
      </section> */}
      <Footer />
    </Fragment>
  );
};

export default AdvancedSpecialSystems;
