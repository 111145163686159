import React, { Fragment, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Tab, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import { LuRefreshCw } from "react-icons/lu";

import "react-toastify/dist/ReactToastify.css";

import logo from "../../assets/img/logo.png";

const Offcanvas = () => {
  const form = useRef();
  const form2 = useRef();
  const [valA, setValA] = useState(Math.floor(Math.random() * 10));
  const [valB, setValB] = useState(Math.floor(Math.random() * 10));
  const [valC, setValC] = useState(Math.floor(Math.random() * 10));
  const [valD, setValD] = useState(Math.floor(Math.random() * 10));
  const Refreshsu = () => {
    setValA(Math.floor(Math.random() * 10));
    setValB(Math.floor(Math.random() * 10));
  };
  const Refreshsa = () => {
    setValC(Math.floor(Math.random() * 10));
    setValD(Math.floor(Math.random() * 10));
  };
  const [email, setEmail] = useState("");
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const sendEmail = (e) => {
    e.preventDefault();
    const av = document.getElementById("aValue").value;
    const bv = document.getElementById("bValue").value;
    const cv = document.getElementById("cValue").value;
    if (isValidEmail(email)) {
      if (parseInt(av) + parseInt(bv) === parseInt(cv)) {
        emailjs
          .sendForm(
            "service_nize47m",
            "template_uer69k4",
            form.current,
            "7KVTSHEK3kgLa2O5S"
          )
          .then(
            (result) => {
              console.log(result.text);
              toast.success(
                "Thanks, your enquiry has been submitted successfully!"
              );
            },
            (error) => {
              console.log(error.text);
            }
          );
        setValA(Math.floor(Math.random() * 10));
        setValB(Math.floor(Math.random() * 10));
        e.target.reset();
      } else {
        toast.error("Error Captcha!");
      }
    } else {
      toast.error("Error Email is invalid!");
      return;
    }
  };

  //sales enquiry
  const [email2, setEmail2] = useState("");
  function isValidEmail2(email2) {
    return /\S+@\S+\.\S+/.test(email2);
  }
  const handleChange2 = (event) => {
    setEmail2(event.target.value);
  };
  const sendEmail2 = (e) => {
    e.preventDefault();
    const av = document.getElementById("dValue").value;
    const bv = document.getElementById("eValue").value;
    const cv = document.getElementById("fValue").value;
    if (isValidEmail2(email2)) {
      if (parseInt(av) + parseInt(bv) === parseInt(cv)) {
        emailjs
          .sendForm(
            "service_nize47m",
            "template_9ajakic",
            form2.current,
            "7KVTSHEK3kgLa2O5S"
          )
          .then(
            (result) => {
              console.log(result.text);
              toast.success(
                "Thanks, your enquiry has been submitted successfully!"
              );
            },
            (error) => {
              console.log(error.text);
            }
          );
        setValC(Math.floor(Math.random() * 10));
        setValD(Math.floor(Math.random() * 10));
        e.target.reset();
      } else {
        toast.error("Error Captcha!");
        return;
      }
    } else {
      toast.error("Error Email is invalid!");
      return;
    }
  };

  return (
    <Fragment>
      {/* About Widget */}
      <div className="widget about-widget">
        <div className="logo sidebarLogo ">
          <img src={logo} alt="" />
        </div>
      </div>
      <Tab.Container defaultActiveKey="support">
        <div className="sidebar advanced-tab ">
          <div className="widget contact-widget">
            <div className="enquiryDev tab-buttons">
              <Nav variant="tabs" className="navEnq">
                <Nav.Item>
                  <Nav.Link eventKey="support">Support</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="sales">sales</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="support">
                <h4 className="widget-title">Support Enquiry</h4>

                <form ref={form} onSubmit={sendEmail}>
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group ">
                        <input
                          type="text"
                          placeholder="Full Name"
                          name="f_name"
                          required
                        />
                        <span className="icon">
                          <i className="far fa-user-circle" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group ">
                        <input
                          type="text"
                          placeholder="Company Name"
                          name="c_name"
                          required
                        />
                        <input
                          type="text"
                          name="title"
                          defaultValue="Support Enquiry"
                          hidden
                        />
                        <input
                          type="text"
                          name="type"
                          defaultValue="Support Required for"
                          hidden
                        />
                        <span className="icon">
                          <i className="far fa-building" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group ">
                        <input
                          type="email"
                          onChange={handleChange}
                          name="email"
                          placeholder="Email Address"
                          required
                        />
                        <span className="icon">
                          <i className="far fa-envelope-open" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group ">
                        <input
                          type="text"
                          placeholder="Phone Number"
                          pattern="\d{10}"
                          title="Please enter exactly 10 digits Phone number"
                          maxLength="10"
                          name="p_no"
                          required
                        />
                        <span className="icon">
                          <i className="far fa-phone" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group select ">
                        <input
                          type="text"
                          placeholder="Support Required for"
                          required
                          name="s_req"
                        />
                        <span className="icon">
                          <i className="far fa-file-alt" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group textarea ">
                        <textarea
                          placeholder="Write Message"
                          className="msgHG"
                          defaultValue={""}
                          required
                          name="msg"
                          rows={5}
                          maxLength="150"
                        />
                        <span className="icon">
                          <i className="far fa-pencil" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="cpatchaDiv">
                        <input
                          type="number"
                          readOnly
                          value={valA}
                          id="aValue"
                        />{" "}
                        <span>+</span>
                        <input
                          type="number"
                          readOnly
                          value={valB}
                          id="bValue"
                        />{" "}
                        <span>=</span>
                        <div className="inputTotal">
                          <input type="number" id="cValue" required />
                        </div>
                        <LuRefreshCw className="refIc" onClick={Refreshsu} />
                      </div>
                    </div>
                    <div className="col-12">
                      <button type="submit" className="main-btn">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </Tab.Pane>
              <Tab.Pane eventKey="sales">
                <h4 className="widget-title">Sales Enquiry</h4>
                <form ref={form2} onSubmit={sendEmail2}>
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group ">
                        <input
                          type="text"
                          name="f_name"
                          placeholder="Full Name"
                          required
                        />
                        <input
                          type="text"
                          name="title"
                          defaultValue="Sales Enquiry"
                          hidden
                        />
                        <input
                          type="text"
                          name="type"
                          defaultValue="Type"
                          hidden
                        />
                        <span className="icon">
                          <i className="far fa-user-circle" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group ">
                        <input
                          name="c_name"
                          type="text"
                          placeholder="Company Name"
                          required
                        />
                        <span className="icon">
                          <i className="far fa-building" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group ">
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange2}
                          placeholder="Email Address"
                          required
                        />
                        <span className="icon">
                          <i className="far fa-envelope-open" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group ">
                        <input
                          type="text"
                          name="p_no"
                          placeholder="Phone Number"
                          pattern="\d{10}"
                          maxLength="10"
                          title="Please enter exactly 10 digits Phone number"
                          required
                        />
                        <span className="icon">
                          <i className="far fa-phone" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group select ">
                        <select required name="s_req">
                          {/* <option value="">
                            instead of support required for
                          </option> */}
                          <option value="Product Test & Validation Solutions">
                            Product Test & Validation Solutions
                          </option>
                          <option value="Proto type & NPD testing">
                            Proto type & NPD Testing
                          </option>
                          <option value="Assembly Automation">
                            Assembly Automation
                          </option>
                          <option value="Process Automation">
                            Process Automation
                          </option>
                        </select>
                        <span className="icon">
                          <i className="far fa-angle-down" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group textarea ">
                        <textarea
                          placeholder="Write Message"
                          name="msg"
                          defaultValue={""}
                          className="msgHG"
                          rows={5}
                          maxLength="150"
                          required
                        />
                        <span className="icon">
                          <i className="far fa-pencil" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="cpatchaDiv">
                        <input
                          type="number"
                          readOnly
                          value={valC}
                          id="dValue"
                        />{" "}
                        <span>+</span>
                        <input
                          type="number"
                          readOnly
                          value={valD}
                          id="eValue"
                        />{" "}
                        <span>=</span>
                        <div className="inputTotal">
                          <input type="number" id="fValue" required />
                        </div>
                        <LuRefreshCw className="refIc" onClick={Refreshsa} />
                      </div>
                    </div>
                    <div className="col-12">
                      <button type="submit" className="main-btn">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </Fragment>
  );
};

export default Offcanvas;
