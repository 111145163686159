import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import recent1 from "../../assets/img/project/6.jpg";
import recent2 from "../../assets/img/project/66.jpg";
import recent3 from "../../assets/img/project/666.jpeg";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import sanityClient from "../../../src/client";
import { LuRefreshCw } from "react-icons/lu";

// import authorimg from "../../assets/img/sidebar/author.jpg";

// import cta from "../../assets/img/sidebar/cta.jpg";

const categories = [
  { title: "Financial Planning" },
  { title: "Relationship Buildup" },
  { title: "Investement Planning" },
  { title: "Marketing Strategy" },
  { title: "Product Strategy" },
];

const recentpost = [
  {
    img: recent1,
    title: "Smashing Podcast Epis With Rach Andrewe",
    postdate: "25 Aug 2020",
  },
  {
    img: recent2,
    title: "Responsive Web And Desktop Develop",
    postdate: "25 Aug 2020",
  },
  {
    img: recent3,
    title: "Django Highlig Models Admin Harnessing",
    postdate: "25 Aug 2020",
  },
];

const tags = [
  { tag: "Business" },
  { tag: "Corporate" },
  { tag: "HTML" },
  { tag: "Finance" },
  { tag: "Investment" },
  { tag: "CSS" },
  { tag: "Planing" },
  { tag: "Creative" },
];

const Blogsidebar = () => {
  const [postdata, setPost] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
          title,
          slug,
          mainImage{
            asset->{
            _id,
            url
          }
        },    
          categories,
          publishedAt          
    }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
    console.log(postdata);
  }, []);
  const [valA, setValA] = useState(Math.floor(Math.random() * 10));
  const [valB, setValB] = useState(Math.floor(Math.random() * 10));
  const RefreshC = () => {
    setValA(Math.floor(Math.random() * 10));
    setValB(Math.floor(Math.random() * 10));
  };
  const [email, setEmail] = useState("");
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const submitfrm = (e) => {
    e.preventDefault();
    const av = document.getElementById("gValue").value;
    const bv = document.getElementById("hValue").value;
    const cv = document.getElementById("iValue").value;
    if (isValidEmail(email)) {
      if (parseInt(av) + parseInt(bv) === parseInt(cv)) {
        toast.success("Thank You for Your Message!");
        e.target.reset();
      } else {
        toast.error("Error Captcha!");
        return;
      }
    } else {
      toast.error("Error Email is invalid!");
      return;
    }
  };
  return (
    <div className="sidebar">
      {/* Recent Post Widget */}
      <div className="widget recent-post-widget">
        <h4 className="widget-title">Recent News</h4>
        <div className="post-loops">
          {postdata &&
            postdata.map((item, i) => (
              <div key={i} className="single-post">
                <div className="post-thumb">
                  <img
                    style={{ height: "55px" }}
                    src={item.mainImage.asset.url}
                    alt=""
                  />
                </div>
                <div className="post-desc">
                  <span className="date">
                    <i className="far fa-calendar-alt" />
                    {new Date(item.publishedAt).toDateString()}
                  </span>
                  <Link to={`/eventdetail/${item.slug.current}`}>
                    {item.title.length <= 45
                      ? item.title
                      : item.title.substring(0, 45) + "..."}
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* comment */}

      <div className="comment-template">
        <div className="comment-form">
          <h3 className="title">Leave a reply</h3>
          <form onSubmit={submitfrm}>
            <div className="row">
              <div className="col-lg-12">
                <div className="input-group mb-25">
                  <input type="text" required placeholder=" Full Name" />
                  <span className="icon">
                    <i className="far fa-user" />
                  </span>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-group mb-25">
                  <input
                    required
                    type="email"
                    onChange={handleChange}
                    placeholder=" Email "
                  />
                  <span className="icon">
                    <i className="far fa-envelope" />
                  </span>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-group mb-25">
                  <input
                    pattern="\d{10}"
                    maxLength="10"
                    title="Please enter exactly 10 digits Phone number"
                    required
                    type="text"
                    placeholder="Your phone no "
                  />
                  <span className="icon">
                    <i className="far fa-phone" />
                  </span>
                </div>
              </div>
              <div className="col-12">
                <div className="input-group textarea mb-25">
                  <textarea
                    required
                    placeholder="Write Message"
                    defaultValue={""}
                  />
                  <span className="icon">
                    <i className="far fa-pencil" />
                  </span>
                </div>
              </div>
              <div className="col-12">
                <div className="cpatchaDiv cpatchaDiv2">
                  <input type="number" readOnly value={valA} id="gValue" />{" "}
                  <span>+</span>
                  <input type="number" readOnly value={valB} id="hValue" />{" "}
                  <span>=</span>
                  <div className="inputTotal">
                    <input type="number" id="iValue" required />
                  </div>
                  <LuRefreshCw className="refIc refIc2" onClick={RefreshC} />
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="main-btn">
                  Send Reply
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Blogsidebar;
