import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
// import Client from "../layouts/Client";
// import { Tab, Nav } from "react-bootstrap";

import line1 from "../../assets/img/lines/11.png";
import line2 from "../../assets/img/lines/12.png";

import solution1 from "../../assets/img/solution/solu10.jpg";
import solution2 from "../../assets/img/solution/solu11.jpg";
import solution3 from "../../assets/img/solution/solu12.jpg";

const E_mobility_testing = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>MELZ Indcosys | e-Mobility Testing</title>
        <meta name="description" content="e-mobility Testing" />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "", bgimg: "em" }} />
      <section className="service-section grey-bg service-line-shape section-gap">
        <div className="container">
          {/* Section Title */}
          <div className="section-title text-center both-border mb-50 mobile-mb">
            <span className="title-tag">Solutions </span>
            <h2 className="title">e-Mobility Testing</h2>
          </div>

          <div className="tab-text-block left-image with-left-circle">
            <span></span>
            <div
              id="e-PDT"
              className="row align-items-center justify-content-center mb-50"
            >
              <div className="col-lg-5 col-md-10 mobile-hd">
                <div className="block-image">
                  <img src={solution1} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title"> PERFORMANCE & DURABILITY TESTING</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      With emerging trend changes towards Technology Push &
                      Market Pull - MELZ is involved in developing Custom built
                      test Rigs for e-Mobility including automotive sensors and
                      modules
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Modularity design of the e-PDT system will aid to carry
                      out both the performance and durability checks of the
                      assembled & sub-assembled parts in compliance with ISO,
                      IEC and EUCAR Standard.
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Customer and standard specific Motor performance (Torque,
                      Speed, Current, Voltage)
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Safety testing - High-voltage isolation test (Hipot,
                      Surge), reverse polarity, short circuit, over current
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Functionality Testing with Full electrical and electronic
                      functionality (Contact bounce, Resistance: Micro-ohm to
                      Mega-ohm, characteristic monitoring: PWM, RMS, peak, rise
                      time, duty cycle etc.., Ripple Voltage, Efficiency Test,
                      Voltage & Current Range, Sleep & Leakage Current )
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      System Architecture is derived to meet the stringent Test
                      compliance of both Indian & International Test Standards
                      with simulated and elevated environmental Test Conditions
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      e-PDT’s are one of its kind for all OEM’s and ancillary
                      suppliers with robust and reliable technology with
                      in-built options for enhancing Environmental stress
                      screening (ESS) test systems, Conformance and
                      interoperability testing, EMC Testing and Chemical
                      Resistance Testing
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 desktop-hd mobile-mt">
                <div className="block-image">
                  <img src={solution1} alt="" />
                </div>
              </div>
            </div>

            <span id="process_validation_machines"></span>
            <div
              id="ILEL-TS"
              className="row align-items-center justify-content-center mb-50"
            >
              <div className="col-lg-12 col-md-12">
                <div className="block-text">
                  <h2 className="title">INLINE, END OF LINE TESTING</h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      In addition to e-Mobility performance and durability stand
                      alone machines, MELZ has designed and delivered testing
                      systems for components and sub assembly parts used in
                      electric vehicles.
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      ILEL-TS used for production line testing of electrical
                      components and critical parts like Battery Module, Battery
                      Charger, DC-DC Converter, Motor, Stator, Power Trains,
                      Digital Cluster, Control Switches, Control Cables, Charge
                      Port, Motor Controller, Power Inverter, Mechanical parts,
                      electrical oil pump, electrical water/coolant pump and
                      much more.
                    </li>
                  </ul>
                </div>
                <div className="block-image">
                  <img className="soluCenter" src={solution2} alt="" />
                </div>
                <div className="block-text">
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      Rigs can be adaptable to be complied with the testing
                      standard at various stages starting from bare board (PCBA)
                      to final functionality checks with inbuilt Traceability
                      option.
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Advantages of having embedded high end closed loop control
                      system with Data Acquisition (DAQ), Logging
                      functionalities with real time stamping and Report
                      generation (Customized) and designed to be interfaced with
                      OBD tool.
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Rigs are fused with functional control algorithm on IIOT
                      platforms and shall have provision for encrypted cloud
                      interface.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <span id="proto_type_testing"></span>
            <div
              id="e-VLT"
              className="row align-items-center justify-content-center mb-50"
            >
              <div className="col-lg-5 col-md-10 mobile-hd">
                <div className="block-image">
                  <img src={solution3} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-10">
                <div className="block-text">
                  <h2 className="title">
                    <span>
                      <p className="lowCase">e</p>
                    </span>
                    -VEHICLE LEVEL TESTING
                  </h2>
                  <ul>
                    <li>
                      <i className="fas fa-check" />
                      In addition to component level testing, electric 2 / 3 /4
                      wheeler vehicles demands for whole vehicle testing wrt to
                      Mechanical stability and Electrical functionality related
                      to overall performance of the vehicle.
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      e-Vehice testing requirements are dynamic in nature and
                      demands for continuous upgrade in testing with standard
                      revision.
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Testing related to electric vehicles goes beyond
                      homologation testing for the vehicles and their
                      components.
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      e-VLT are beneficial to ensure electrical safety of users,
                      equipment and infrastructure.
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      Communication interface to automotive bus diagnostics
                      (CAN, J1850 / LIN) and auditable reports enhance OEM
                      compliance with the standard and aid for global reacht
                    </li>
                    <li>
                      <i className="fas fa-check" />
                      MELZ has developed and commissioned multiple vehicle level
                      test machines to determine mechanical stability using
                      state-of-art electro mechanical CAM Drum Test Rigs with
                      combination of 36 test profiles and bumps.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 desktop-hd mobile-mt">
                <div className="block-image">
                  <img src={solution3} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="line-one">
            <img src={line2} alt="line-shape" />
          </div>
          <div className="line-two">
            <img src={line1} alt="line-shape" />
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
};

export default E_mobility_testing;
