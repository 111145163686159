import React, { Component, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../layouts/Blogsidebar";

import blogbanner from "../../../assets/img/project/666.jpeg";

import Breadcrumbs from "../../layouts/Breadcrumbs";

import BlockContent from "@sanity/block-content-to-react";
//import { PortableText } from "@portabletext/react";
import sanityClient from "../../../client";

const Contentfile = () => {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();
  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          file{
            asset->{
            _id,
            url
          }
        },
          
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);
  const clkOn = (e) => {
    e.preventDefault();
    const urlL = document.getElementById("urlGet").value;
    window.location.href = urlL;
    console.log(urlL);
    setTimeout(() => {
      window.location.href = "/";
    }, 2000);
  };

  //  console.log(postData.file.asset.url);
  // if (!postData) {
  //   return <div>Loading...</div>;
  // }
  return (
    <>
      <Breadcrumbs breadcrumb={{ pagename: "File Transfer" }} />
      {postData ? (
        <>
          <section className="blog-section section-gap-top">
            <div className="container">
              <div className="row justify-content-center">
                <form onSubmit={clkOn}>
                  <input
                    type="text"
                    value={postData.file.asset.url}
                    id="urlGet"
                    readOnly
                    hidden
                  />
                  <div className="col-12">
                    <button type="submit" onClick={clkOn} className="main-btn">
                      Click to Download
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <div className="FileRemove">
            <h3>File has been removed</h3>
          </div>
        </>
      )}
    </>
  );
};

export default Contentfile;
