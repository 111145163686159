import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Client from "../layouts/Client";
import Content from "../sections/About/Content";
import Cta from "../sections/homethree/Cta";
import notFound404 from "../../assets/img/404.jpg";
import { Link } from "react-router-dom";

class PageNotfound extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>MELZ Indcosys | 404 </title>
          <meta name="description" content="#" />
        </MetaTags>
        <Header />
        <Breadcrumbs breadcrumb={{ pagename: "404 Page" }} />
        <div className="container">
          <div>
            <img src={notFound404} />
            <h2 className="tcenter">Page Not Found!</h2>
            <p className="tcenter">
              Oops! The page you are looking for does not exist. It might have
              been moved or deleted.
            </p>
            <div className="col-12 text-center">
              <Link className="main-btn" to="/">
                {" "}
                Back to home
              </Link>
            </div>
          </div>
        </div>
        <Cta />
        <Footer />
      </Fragment>
    );
  }
}

export default PageNotfound;
