import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import quote from "../../../assets/img/icons/quote.png";

import sanityClient from "../../../../src/client";

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="slick-arrow next-arrow" onClick={onClick}>
      <i className="fal fa-long-arrow-right" />
    </button>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="slick-arrow prev-arrow" onClick={onClick}>
      <i className="fal fa-long-arrow-left" />
    </button>
  );
};

function Testimonials(props) {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: true,
    fade: false,
    dots: false,
    dotsClass: "testimonial-dots slick-dots slick-thumb",
    swipe: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: function (i) {
      return (
        <img src={`assets/img/testimonial/thumb/author-${i + 1}.png`} alt="" />
      );
    },
    responsive: [
      {
        breakpoint: 576,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  const [testimoni, setTestimonial] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "testimonials"]{
          cname,
          designation,
          msg,
          publishedAt,         
    }`
      )
      .then((data) => setTestimonial(data))
      .catch(console.error);
  }, []);
  return (
    <>
      <span id="testimonials"></span>
      <section className="testimonial-section-two section-gap">
        <div className="container">
          <div className="row justify-content-center no-gutters">
            <div className="col-lg-9">
              <div className="section-title both-border text-center ">
                <span className="title-tag">Testimonials</span>
                <h2 className="title">Reviews</h2>
              </div>
              <Slider
                className="testimonial-items"
                id="testimonialSliderTwo"
                {...settings}
              >
                {testimoni &&
                  testimoni.map((item, i) => (
                    <div key={i} className="testimonial-item">
                      <div className="author-img"></div>
                      <div className="content">
                        <p>
                          <span className="quote-top">
                            <i className="fas fa-quote-left" />
                          </span>
                          {item.msg}
                          <span className="quote-bottom">
                            <i className="fas fa-quote-right" />
                          </span>
                        </p>
                      </div>
                      <div className="author-name">
                        <h4>{item.cname}</h4>
                        <span>{item.designation}</span>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
        <div className="testimonial-quote-icon">
          <img src={quote} alt="quote" />
        </div>
      </section>
    </>
  );
}

export default Testimonials;
